import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  const { currentUser } = useSelector((state) => state.user)
  const navigate = useNavigate()

  return (
    <div
      className={`flex flex-col md:flex-row items-center justify-center py-20 text-white ${lightBg ? 'bg-[#eff2f2]' : 'bg-[#1c2237]'}  ${imgStart ? 'flex-row-reverse' : 'flex-row'}`}
    >
      <div className='w-full sm:w-[650px] max-w-[650px] flex items-center justify-center md:justify-start px-5 '>
        <div className='max-w-[540px] pb-16'>
          <h5 className='text-red-500 uppercase leading-4 font-bold tracking-widest mb-4'>
            {topLine}
          </h5>
          <h1
            className={`text-5xl mb-6 leading-[1.1] font-semibold ${lightText ? 'text-white' : 'text-[#1c2237]'}`}
          >
            {headline}
          </h1>
          <p
            className={`max-w-[440px] mb-9 text-lg leading-6 ${lightTextDesc ? 'text-white' : 'text-[#1c2237]'}`}
          >
            {description}
          </p>
          <button
            type='button'
            onClick={() => navigate(currentUser ? '/products' : '/pricing')}
            className={`text-lg hover:bg-blue-500 w-60 active:scale-[0.98] h-12 rounded-md transition-all bg-blue-600 text-white shadow-lg`}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
      <div
        className={`w-full sm:w-[650px] max-w-[650px] flex items-center md:justify-start px-3`}
      >
        <div className='max-w-[500px] '>
          <img src={img} alt={alt} className='w-full' />
        </div>
      </div>
    </div>
  )
}

export default HeroSection
