import productsTypes from './products.types'

const initialState = {
  loading: false,
  error: null,
  isLastPage: null,
  totalCount: null,
  products: [],
}

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case productsTypes.PRODUCTS_READ_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case productsTypes.PRODUCTS_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        isLastPage: action.payload.isLastPage,
        totalCount: action.payload.totalCount,
        products: action.payload.data,
      }
    case productsTypes.PRODUCTS_READ_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case productsTypes.PRODUCTS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case productsTypes.PRODUCTS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: state.products.filter(
          (product) => product.id !== action.payload.id
        ),
      }
    case productsTypes.PRODUCTS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default productsReducer
