import React from 'react'

const Paragraph = ({ header, text }) => {
  return (
    <div>
      <h2
        style={{
          padding: '10px',
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '25px',
          color: 'hsl(227, 35%, 25%)',
        }}
      >
        {header}
      </h2>
      <p style={{ textAlign: 'left' }}>{'\t' + text}</p>
    </div>
  )
}

export default Paragraph
