import React, { useEffect } from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { MdKeyboardArrowRight } from 'react-icons/md'

const Pagination = ({
  totalCount,
  currentPage,
  setCurrentPage,
  products,
  containerRef,
  pageSize,
}) => {
  const totalPage = Math.ceil(totalCount / pageSize)

  useEffect(() => {
    if (containerRef.current) {
      window.scrollTo({ top: 0, behavior: 'instant' })
      containerRef.current.scrollTo({ top: 0, behavior: 'instant' })
    }
  }, [products])

  if (totalPage <= 1) return null

  const pagination = []
  if (totalPage <= 7) {
    pagination.push(
      ...Array.from({ length: totalPage }, (_, index) => index + 1)
    )
  } else {
    if (currentPage < 5) {
      pagination.push(1, 2, 3, 4, 5, '...', totalPage)
    } else if (currentPage > totalPage - 4) {
      pagination.push(
        1,
        '...',
        totalPage - 4,
        totalPage - 3,
        totalPage - 2,
        totalPage - 1,
        totalPage
      )
    } else {
      pagination.push(
        1,
        '...',
        currentPage - 1,
        currentPage,
        currentPage + 1,
        '...',
        totalPage
      )
    }
  }

  const handlePageClick = (item) => {
    if (item !== '...') setCurrentPage(item)
  }

  const handleNextClick = () => {
    if (currentPage < totalPage) setCurrentPage(currentPage + 1)
  }

  const handlePrevClick = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  return (
    <div className='flex justify-center items-center gap-2'>
      <div
        onClick={handlePrevClick}
        className={`w-8 h-8 flex justify-center items-center rounded-full ${currentPage === 1 ? 'text-gray-400 cursor-default' : 'cursor-pointer hover:bg-gray-200 active:bg-gray-300 transition-colors'}`}
      >
        <MdKeyboardArrowLeft className='text-2xl' />
      </div>
      {pagination.map((item, index) => (
        <div
          key={index}
          className={`
          ${item === currentPage ? 'bg-sky-500 text-white hover:bg-sky-600 active:bg-sky-400' : 'bg-white'}
          ${item === '...' ? 'cursor-default' : 'cursor-pointer border w-8 h-8 flex justify-center items-center rounded-full'}
          ${item !== '...' && item !== currentPage && 'hover:bg-gray-200'}
          transition-colors
        `}
          onClick={() => handlePageClick(item)}
        >
          {item}
        </div>
      ))}
      <div
        onClick={handleNextClick}
        className={`w-8 h-8 flex justify-center items-center rounded-full ${currentPage === totalPage ? 'text-gray-400 cursor-default' : 'cursor-pointer hover:bg-gray-200 active:bg-gray-300 transition-colors'}`}
      >
        <MdKeyboardArrowRight className='text-2xl' />
      </div>
    </div>
  )
}

export default Pagination
