const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT

export const fetchFavorites = async (userId) => {
  if (!userId) {
    throw new Error('User ID is required to fetch favorites.')
  }
  try {
    const response = await fetch(`${ENDPOINT}/api/v1/favorites/${userId}`)
    if (!response.ok) {
      throw new Error('HTTP error, status = ' + response.status)
    }
    const favorites = await response.json()
    return favorites
  } catch (error) {
    throw new Error('Error fetching favorites: ' + error.message)
  }
}

export const addFavorite = async (favorite) => {
  try {
    const response = await fetch(`${ENDPOINT}/api/v1/favorites`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(favorite),
    })
    if (!response.ok) {
      throw new Error('HTTP error, status = ' + response.status)
    }
    const newFavorite = await response.json()
    return newFavorite[0]
  } catch (error) {
    throw new Error('Error adding favorite: ' + error.message)
  }
}

export const removeFavorite = async (favorite) => {
  try {
    const response = await fetch(`${ENDPOINT}/api/v1/favorites`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(favorite),
    })
    if (!response.ok) {
      throw new Error('HTTP error, status = ' + response.status)
    }
    return true
  } catch (error) {
    throw new Error('Error removing favorite: ' + error.message)
  }
}
