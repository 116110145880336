/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { useSelector } from 'react-redux'

const TablePagination = ({
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
}) => {
  const { totalCount } = useSelector((state) => state.productsData)
  const totalPage = Math.ceil(totalCount / pageSize)
  const handleNextClick = () => {
    if (currentPage < totalPage) setCurrentPage(currentPage + 1)
  }

  const handlePrevClick = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handlePageSizeChange = (event) => {
    const selectedSize = event.target.value
    setPageSize(selectedSize)
    if (selectedSize > totalCount) setCurrentPage(1)
  }

  return (
    <div className='flex gap-2'>
      <div className='flex flex-row items-center w-52'>
        <label htmlFor='selectBox' className=''>
          Items per page :
        </label>
        <select
          id='selectBox'
          value={pageSize}
          onChange={handlePageSizeChange}
          className='text-white border ring-0 rounded bg-inherit cursor-pointer ml-2'
        >
          {[20, 40, 60, 80, 100].map((item) => (
            <option
              className='text-black cursor-pointer'
              key={item}
              value={item}
            >
              {item}
            </option>
          ))}
        </select>
      </div>

      <div className='flex justify-end items-center w-44'>
        {Math.min((currentPage - 1) * pageSize + 1, totalCount)} -{' '}
        {Math.min(currentPage * pageSize, totalCount)} of {totalCount}
      </div>
      <div className='flex items-center w-20'>
        <div
          className={`w-8 h-8 flex justify-center items-center rounded-full ${currentPage === 1 ? 'text-gray-400 cursor-default' : 'cursor-pointer hover:bg-blue-900 active:bg-blue-950  transition-colors'}`}
          onClick={handlePrevClick}
        >
          <MdKeyboardArrowLeft className='text-2xl' />
        </div>
        <div
          className={`w-8 h-8 flex justify-center items-center rounded-full ${currentPage === totalPage ? 'text-gray-400 cursor-default' : 'cursor-pointer hover:bg-blue-900 active:bg-blue-950 transition-colors'}`}
          onClick={handleNextClick}
        >
          <MdKeyboardArrowRight className='text-2xl' />
        </div>
      </div>
    </div>
  )
}

export default TablePagination
