const favoritesTypes = {
  FAVORITES_READ_REQUEST: 'FAVORITES_READ_REQUEST',
  FAVORITES_READ_SUCCESS: 'FAVORITES_READ_SUCCESS',
  FAVORITES_READ_FAILURE: 'FAVORITES_READ_FAILURE',

  FAVORITES_CREATE_REQUEST: 'FAVORITES_CREATE_REQUEST',
  FAVORITES_CREATE_SUCCESS: 'FAVORITES_CREATE_SUCCESS',
  FAVORITES_CREATE_FAILURE: 'FAVORITES_CREATE_FAILURE',

  FAVORITES_DELETE_REQUEST: 'FAVORITES_DELETE_REQUEST',
  FAVORITES_DELETE_SUCCESS: 'FAVORITES_DELETE_SUCCESS',
  FAVORITES_DELETE_FAILURE: 'FAVORITES_DELETE_FAILURE',
}

export default favoritesTypes
