import productsTypes from './products.types'

export const readProducts = (query) => ({
  type: productsTypes.PRODUCTS_READ_REQUEST,
  payload: query,
})

export const deleteProduct = (id) => ({
  type: productsTypes.PRODUCTS_DELETE_REQUEST,
  payload: id,
})
