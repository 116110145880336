import React, { useState, useEffect, useRef } from 'react'
import { IoIosArrowUp } from 'react-icons/io'
import { IoIosArrowDown } from 'react-icons/io'
import { RxIdCard } from 'react-icons/rx'
import { ImTable } from 'react-icons/im'
import { FaFilter } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { ImArrowLeft } from 'react-icons/im'
import { useDispatch, useSelector } from 'react-redux'
import { readProducts } from '../../redux/Products/products.actions'
import { modifyProductData } from '../../components/Shared/modifyProductData'
import { accordionData, sortItems } from './data'
import CardView from '../../components/Views/CardView'
import TableView from '../../components/Views/TableView'
import FilterAccordion from './FiterAccordion'
import Pagination from './Pagination'
import FadeLoader from 'react-spinners/FadeLoader'

import './styles.css'

const WholesaleProducts = () => {
  const sortMenuRef = useRef(null)
  const tableViewFilterPanelRef = useRef(null)
  const dispatch = useDispatch()
  const containerRef = useRef(null)
  const { products, totalCount, loading } = useSelector(
    (state) => state.productsData
  )
  const [tableView, setTableView] = useState(false)
  const [modifiedProducts, setModifiedProducts] = useState([])
  const [mobileFilterPanel, setMobileFilterPanel] = useState(false)
  const [openSortMenu, setOpenSortMenu] = useState(false)
  const [tableViewFilterPanel, setTableViewFilterPanel] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [sortBy, setSortBy] = useState({
    field: 'createdDate',
    direction: 'desc',
  })
  const initialFilterOptions = {
    category: 'All Products',
    amazonNotComp: false,
    buyBoxOpp: false,
    outOfStock: false,
    notOutOfStock: false,
    norman: false,
    amzCA_ST: false,
    amzCOM_ST: false,
  }

  const [filterOptions, setFilterOptions] = useState(initialFilterOptions)
  const [filterTempOps, setFilterTempOps] = useState(initialFilterOptions)

  useEffect(() => {
    dispatch(
      readProducts({
        filterOptions,
        sortBy,
        currentPage,
        pageSize,
        productType: 'wholesale',
      })
    )
  }, [filterOptions, sortBy, currentPage, pageSize])

  useEffect(() => {
    if (products?.length) {
      const newData = modifyProductData(products)
      setModifiedProducts([...newData])
    } else setModifiedProducts([])
  }, [products])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortMenuRef.current && !sortMenuRef.current.contains(event.target)) {
        setOpenSortMenu(false)
      }
      if (
        tableViewFilterPanelRef.current &&
        !tableViewFilterPanelRef.current.contains(event.target)
      ) {
        setTableViewFilterPanel(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickSortMenu = (sortItem) => {
    setSortBy({ field: sortItem.field, direction: sortItem.direction })
    setOpenSortMenu(false)
  }

  const handleClearAllFilters = () => {
    setFilterOptions(initialFilterOptions)
  }

  const handleApplyFilters = () => {
    if (JSON.stringify(filterTempOps) !== JSON.stringify(filterOptions)) {
      setFilterOptions(filterTempOps)
      setCurrentPage(1)
    }
    setMobileFilterPanel(false)
  }

  const handleCloseMobileFilterPanel = () => {
    setFilterTempOps(filterOptions)
    setMobileFilterPanel(false)
  }

  // When user scrolls up with visible footer, window scrolls to top for footer to slide down.
  useEffect(() => {
    let isScrolling
    const handleScroll = (event) => {
      const scrollTop = window.scrollY
      const { deltaY } = event

      if (deltaY < 0 && scrollTop > 0) {
        window.scrollTo({ top: scrollTop + deltaY, behavior: 'smooth' })
        event.preventDefault()
        clearTimeout(isScrolling)
        isScrolling = setTimeout(() => {
          window.scrollTo({ top: scrollTop + deltaY, behavior: 'smooth' })
        }, 50)
      }
    }
    window.addEventListener('wheel', handleScroll)
    return () => {
      window.removeEventListener('wheel', handleScroll)
      clearTimeout(isScrolling)
    }
  }, [])

  const handlePageSizeChange = (event) => {
    const selectedSize = event.target.value
    setPageSize(selectedSize)

    const maxPageNumber = Math.ceil(totalCount / selectedSize)
    if (currentPage > maxPageNumber) {
      setCurrentPage(maxPageNumber)
    }
  }

  return (
    <>
      {tableView ? (
        <>
          <div
            className={`flex justify-between items-center p-2 my-3 rounded-md border shadow-md mx-auto ${tableView ? 'w-[98%] lg:w-[95%]' : 'max-w-[1100px]'}`}
          >
            <div
              className='flex justify-center items-center gap-2 w-28 h-9 text-gray-800 rounded-md border hover:bg-gray-50 hover:shadow-sm cursor-pointer hover:opacity-95 active:scale-95 transition-all hover:duration-300'
              onClick={() => setTableViewFilterPanel(true)}
            >
              <FaFilter />
              FILTER
            </div>
            <span className='text-lg font-bold'>PRODUCTS</span>
            <div
              className='flex justify-center items-center gap-2 w-32 h-9 text-gray-800 rounded-md border hover:bg-gray-50 hover:shadow-sm cursor-pointer hover:opacity-95 active:scale-95 transition-all hover:duration-300'
              onClick={() => setTableView(!tableView)}
            >
              <RxIdCard className='text-xl' />
              <p className='min-w-[68px]'>Card View</p>
            </div>
          </div>
          <TableView
            data={modifiedProducts}
            page='products'
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div
            className={`hide-scrollbar fixed left-0 top-0 p-3 bg-white text-black z-[1000] shadow-md overflow-y-auto h-screen ${tableViewFilterPanel ? 'w-full sm:w-96  visible' : 'w-0 invisible'} transition-width duration-150 ease-linear`}
            ref={tableViewFilterPanelRef}
          >
            <div className='flex items-baseline justify-between my-2'>
              <div className='flex items-center gap-2 text-xl font-bold'>
                <FaFilter />
                FILTERS
              </div>
              {JSON.stringify(filterOptions) !==
                JSON.stringify(initialFilterOptions) && (
                <div
                  onClick={handleClearAllFilters}
                  className='text-red-600 cursor-pointer hover:underline active:scale-95'
                >
                  CLEAR ALL
                </div>
              )}
              <div
                className='flex justify-center items-center w-9 h-9 rounded-full cursor-pointer transition-colors duration-200 hover:bg-[#e8e8e8]'
                onClick={() => setTableViewFilterPanel(false)}
              >
                <ImArrowLeft />
              </div>
            </div>
            {accordionData.map((item) => (
              <FilterAccordion
                key={item.header}
                {...{
                  item,
                  filterOptions,
                  setFilterOptions,
                  filterTempOps,
                  setFilterTempOps,
                  mobileFilterPanel,
                  setCurrentPage,
                }}
              />
            ))}
          </div>
        </>
      ) : (
        <div className='flex h-[calc(100vh-60px)] font-sans text-gray-800'>
          {/* left column */}
          <div className='hidden lg:block w-full overflow-auto custom-scroll lg:w-[300px] min-w-[260px] p-3'>
            <div className='flex items-baseline justify-between mb-4'>
              <div className='flex items-center gap-2 text-xl font-bold'>
                <FaFilter />
                FILTERS
              </div>
              {(Object.values(filterOptions).some((value) => value === true) ||
                filterOptions.category !== 'All Products') && (
                <div
                  onClick={handleClearAllFilters}
                  className='text-red-600 cursor-pointer hover:underline active:scale-95'
                >
                  CLEAR ALL
                </div>
              )}
            </div>
            {accordionData?.map((item) => (
              <FilterAccordion
                key={item.header}
                {...{
                  item,
                  filterOptions,
                  setFilterOptions,
                  filterTempOps,
                  setFilterTempOps,
                  mobileFilterPanel,
                  setCurrentPage,
                }}
              />
            ))}
            <div className='h-20'></div>
          </div>
          {/* right column */}
          <div
            ref={containerRef}
            className='flex-1 h-full overflow-auto custom-scroll p-4'
          >
            <div className='border-b max-w-[1100px] mb-5 shadow-sm p-1 rounded-md shadow-gray-600 pb-1 flex justify-between font-semibold'>
              <div className='flex flex-col md:flex-row w-1/2 gap-2 md:gap-0'>
                <div className='w-52 flex items-center'>
                  Total items: {totalCount}
                </div>
                {/* Select menu for items per page. */}
                <div className='w-full flex items-center justify-start md:justify-center'>
                  <label htmlFor='selectBox'>Items per page:</label>
                  <select
                    id='selectBox'
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    className='text-gray-800 ring-0 ring-offset-0 outline-none cursor-pointer ml-1'
                  >
                    {[20, 40, 60, 80, 100].map((item) => (
                      <option
                        className='text-black cursor-pointer'
                        key={item}
                        value={item}
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='flex flex-col md:flex-row w-1/2'>
                {/* Sort menu */}
                <div className='w-full order-2 md:order-1 flex items-center justify-end md:justify-center'>
                  <div
                    ref={sortMenuRef}
                    className='relative flex gap-1 items-center cursor-pointer'
                    onClick={() => setOpenSortMenu(!openSortMenu)}
                  >
                    <div
                      className={`absolute flex flex-col justify-between overflow-hidden top-7 right-0 bg-white shadow-lg shadow-gray-400 rounded-md z-30 w-40 ${openSortMenu ? 'h-48 py-2' : 'h-0'} transition-height duration-100`}
                    >
                      {sortItems?.map((sortItem, index) => (
                        <p
                          key={index}
                          onClick={() => handleClickSortMenu(sortItem)}
                          className={`min-w-96 py-0.5 ${sortBy?.field === sortItem?.field ? 'font-semibold' : 'font-normal'} hover:bg-gray-100 px-3 `}
                        >
                          {sortItem?.title}
                        </p>
                      ))}
                    </div>
                    <div>
                      Sort By:{' '}
                      <span className='text-sm text-gray-500'>
                        {
                          sortItems.find((item) => item.field === sortBy.field)
                            ?.title
                        }
                      </span>
                    </div>
                    {openSortMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </div>
                </div>
                {/* Buttons to toggle between CartView and TableView */}
                <div className='md:w-32 order-1 md:order-2 flex items-center justify-end'>
                  <div
                    className='flex justify-center items-center gap-2 w-28 h-8 text-gray-800 rounded-md border hover:bg-gray-50 hover:shadow-sm cursor-pointer hover:opacity-95 active:scale-95 transition-all hover:duration-300'
                    onClick={() => setTableView(!tableView)}
                  >
                    {tableView ? (
                      <RxIdCard className='text-lg' />
                    ) : (
                      <ImTable className='text-lg' />
                    )}
                    <p className='min-w-[68px]'>
                      {tableView ? 'Card View' : 'Table View'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {loading && (
              <div className='fixed z-50 left-1/2 top-1/3 transform -translate-1/2 '>
                <FadeLoader color='#2E7D32' />
              </div>
            )}
            {products.length > 0 && (
              <>
                <CardView
                  data={modifiedProducts}
                  page='products'
                  containerRef={containerRef}
                />
                <div className='w-full mt-10 mb-5 flex justify-center'>
                  <Pagination
                    {...{
                      totalCount,
                      currentPage,
                      setCurrentPage,
                      products,
                      containerRef,
                      pageSize,
                    }}
                  />
                </div>
              </>
            )}

            {!products.length > 0 && !loading && (
              <p className='text-lg mt-5'>
                No search results found. Please try updating filter conditions.
              </p>
            )}
          </div>
          {/* Filter Panel for mobile devices, smaller than md(768px) */}
          <div
            className={`hide-scrollbar lg:hidden fixed bottom-0 left-0 h-9 z-[1000] ${mobileFilterPanel && 'w-full h-screen overflow-y-auto bg-white transition-height duration-200 items-start'}`}
          >
            <div
              className={`bg-sky-600 text-white font-semibold flex items-center justify-between px-3 ${mobileFilterPanel ? 'w-full h-10' : 'w-24 h-9 opacity-85'}`}
            >
              <div
                className={`flex items-center gap-2 ${mobileFilterPanel || 'cursor-pointer'}`}
                onClick={() => setMobileFilterPanel(true)}
              >
                <FaFilter />
                <span>FILTER</span>
              </div>

              <div
                className={`${mobileFilterPanel ? 'flex items-center gap-5 ' : 'hidden'}`}
              >
                <span
                  onClick={handleApplyFilters}
                  className='cursor-pointer hover:text-gray-200'
                >
                  APPLY
                </span>
                <span
                  onClick={() => setFilterTempOps(initialFilterOptions)}
                  className='cursor-pointer hover:text-gray-200'
                >
                  CLEAR ALL
                </span>
                <IoClose
                  className='text-2xl cursor-pointer hover:text-gray-200'
                  onClick={handleCloseMobileFilterPanel}
                />
              </div>
            </div>
            <div
              className={`${mobileFilterPanel ? 'block bg-white border-0 px-4' : 'hidden'}`}
            >
              {accordionData.map((item) => (
                <FilterAccordion
                  key={item.header}
                  {...{
                    item,
                    filterOptions,
                    setFilterOptions,
                    filterTempOps,
                    setFilterTempOps,
                    mobileFilterPanel,
                    setCurrentPage,
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default WholesaleProducts
