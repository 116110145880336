import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSubscriptionUpdateStart } from '../../redux/User/user.actions'
import { Link } from 'react-router-dom'
import './styless.css'

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
})

const Success = () => {
  const { currentUser } = useSelector(mapState)
  const dispatch = useDispatch()

  useEffect(() => {
    function handlePaymentSuccess() {
      console.log(currentUser)
      if (
        currentUser &&
        currentUser.id &&
        currentUser.subscription &&
        currentUser.subscription.sessionId &&
        !currentUser.subscription.planId
      ) {
        fetch(
          process.env.REACT_APP_BACKEND_ENDPOINT +
            '/api/v1/stripe/payment-success',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            mode: 'cors', // Ensure you're making a CORS request
            body: JSON.stringify({
              sessionId: currentUser.subscription.sessionId,
              firestoreId: currentUser.id,
            }),
          }
        )
          .then((res) => {
            if (res.ok) return res.json()
            return res.json().then((json) => Promise.reject(json))
          })
          .then(
            ({
              // message,
              sessionId,
              planId,
              planStartDate,
              planEndDate,
              planDuration,
              subscriptionId,
              status,
            }) => {
              if (
                planId &&
                planStartDate &&
                planEndDate &&
                planDuration &&
                subscriptionId &&
                status
              ) {
                dispatch(
                  handleSubscriptionUpdateStart({
                    sessionId,
                    planId,
                    planStartDate,
                    planEndDate,
                    planDuration,
                    subscriptionId,
                    status,
                  })
                )
              }
            }
          )
          .catch((e) => {
            console.log(e.error)
          })
      }
    }

    handlePaymentSuccess()
  }, [currentUser, dispatch])

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white rounded-lg shadow-md p-8 max-w-md'>
        <h1 className='text-2xl font-semibold text-green-600 mb-4'>
          Payment Successful!
        </h1>
        <p className='text-gray-700 mb-2'>Thank you for your payment.</p>
        <p className='text-gray-500 mb-4'>
          Your transaction has been completed successfully.
        </p>
        <div className='flex justify-center'>
          <Link to='/products'>
            <button className='bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-200'>
              Start Exploring Opportunities
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Success
