import favoritesTypes from './favorites.types'

export const readFavorites = (userId) => ({
  type: favoritesTypes.FAVORITES_READ_REQUEST,
  payload: userId,
})

export const createFavorite = (favorite) => ({
  type: favoritesTypes.FAVORITES_CREATE_REQUEST,
  payload: favorite,
})

export const deleteFavorite = (favorite) => ({
  type: favoritesTypes.FAVORITES_DELETE_REQUEST,
  payload: favorite,
})
