import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Pricing from '../pages/Pricing'

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
})

const WithSubscription = (props) => {
  const navigate = useNavigate()
  const { currentUser } = useSelector(mapState)

  const isCurrentUserSubscribed = () => {
    if (
      currentUser &&
      currentUser.subscription &&
      currentUser.subscription.planId &&
      currentUser.subscription.planEndDate
    ) {
      if (currentUser.subscription.planId.length === 0) {
        return false
      }

      if (currentUser.subscription.planEndDate === 0) {
        return false
      }
      const endDate = new Date(currentUser.subscription.planEndDate)
      const currentDate = new Date()

      if (currentDate > endDate) {
        return false
      }

      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (!currentUser) {
      navigate('/')
    }
  }, [currentUser, navigate])

  return isCurrentUserSubscribed() ? (
    <div>{props.children}</div>
  ) : (
    <Pricing text='Please subscribe to see products' />
  )
}

export default WithSubscription
