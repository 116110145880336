export const sortItems = [
  { field: 'createdDate', title: 'Newest Items', direction: 'desc' },
  { field: 'unitSales', title: 'Highest Unit Sales', direction: 'desc' },
  { field: 'sellRank', title: 'Best Sellers Rank', direction: 'asc' },
  { field: 'profit', title: 'Highest Profit', direction: 'desc' },
  { field: 'profitRatio', title: 'Highest Profit Ratio', direction: 'desc' },
  { field: 'buyPrice', title: 'Lowest Buy Price', direction: 'asc' },
]

export const accordionData = [
  {
    header: 'Categories',
    content: [
      'All Products',
      'Arts, Crafts & Sewing',
      'Clothing, Shoes & Jewelry',
      'Unique Finds',
      'Books',
      'Health & Household',
      'Pet Supplies',
      'Tools & Home Improvement',
      'Appliances',
      'Beauty & Personal Care',
      'Office Products',
      'Grocery & Gourmet Food',
      'Patio, Lawn & Garden',
      'Toys & Games',
      'Electronics',
      'Video Games',
      'Industrial & Scientific',
      'Automotive',
      'Home & Kitchen',
      'Baby',
      'Kitchen & Dining',
      'Computers & Accessories',
      'Sports & Outdoors',
      'CDs & Vinyl',
      'Musical Instruments',
      'Cell Phones & Accessories',
      'Movies & TV',
      'Climate Pledge Friendly',
      'Amazon Renewed',
    ],
  },
  {
    header: 'Prefered Picks',
    content: [
      { title: 'Buy Box Opportunity', field: 'buyBoxOpp' },
      { title: 'Amazon Not Competitor', field: 'amazonNotComp' },
      { title: 'Not Out of Stock Products', field: 'notOutOfStock' },
      { title: 'Out of Stock Products', field: 'outOfStock' },
    ],
  },
  {
    header: 'Buy From',
    content: [
      { title: 'Amazon CA' },
      { title: 'Amazon US' },
      { title: 'Walmart CA' },
      { title: 'Walmart US' },
      { title: 'Sams Club US' },
      { title: 'Target US' },
      { title: 'Dollarama CA' },
    ],
  },
  {
    header: 'Sell To',
    content: [{ title: 'Amazon CA' }, { title: 'Amazon US' }],
  },
]
