import noImage from '../../assets/no-image-available.jpg'

export const modifyProductData = (data) => {
  const modifiedFavorites = data.map((item) => {
    const buyPrice = item.buyPrice
    const estimatedTax = (buyPrice / 10).toFixed(2)
    const sellPackof = item.sellPackOf || 1
    const buyPackof = item.buyPackOf || 1
    const totalBuyCost = (
      (parseInt(sellPackof) * (buyPrice + parseFloat(estimatedTax))) /
      parseInt(item.buyPackOf || 1)
    ).toFixed(2)
    const profitMargin = ((item.profit / item.sellPrice) * 100).toFixed(2)
    const buyLink = item.buyLink

    // Tooltip text
    const modTotalCostTooltip = `${parseInt(sellPackof) / parseInt(buyPackof || 1).toFixed(2)} (bundle) x ($${buyPrice} (buy price) + $${estimatedTax} (estimated tax)) = $${totalBuyCost} (total buy cost)`
    const modProfitTooltip = `$${item.sellPrice} (sell price) - $${totalBuyCost} (total buy cost) - $${item.fbaFee} (total amazon fee) = $${item.profit} (profit)`
    const referralFee = (
      (item.referralFeePercent / 100) *
      item.sellPrice
    ).toFixed(2)
    const justFBA = (item.fbaFee - referralFee).toFixed(2)
    const modFbaFeeTooltip = `$${referralFee} (referral fee) + $${justFBA} (fba fee) = $${item.fbaFee} (total amazon fee)`
    const modProfitMarginTooltip = `$${item.profit} (profit) / $${item.sellPrice} (sell price) = ${profitMargin}% (profit margin)`

    // Buttons URL
    const market = item.sellCountryCode === 'ca' ? 6 : 1
    const marketExt = item.sellCountryCode === 'ca' ? 'ca' : 'com'
    const amazonURL = `https://sellercentral.amazon.${marketExt}/product-search/search?q=${item?.ASIN}&ref_=xx_catadd_dnav_xx`
    const searchQuery = item.name.replace(' ', '+')
    const googleSearchUrl = `https://www.google.com/search?q=${searchQuery}`
    const keepaURL = `https://keepa.com/#!product/${market}-${item?.ASIN}`

    return {
      ...item,
      modROI: item.profitRatio ? `${item.profitRatio}%` : 'N/A',
      modProductThumbnail: item.thumbnail ?? noImage,
      modProfit: item.sellPrice > 0 ? `$${item.profit}` : 'N/A',
      modBuyMarket: item.buyMarket || null,
      modBuyPrice: `$${buyPrice}`,
      buyLink,
      modSellPrice:
        item.sellPrice && item.sellPrice > 0
          ? `$${item.sellPrice}`
          : 'Out of Stock',
      modSellPackOf: sellPackof,
      modBuyPackOf: buyPackof,
      modEstimatedTax: `$${estimatedTax}`,
      modSellRank: `#${item.sellRank}`,
      modUnitSales: item.unitSales > 0 ? item.unitSales : 'unknown',
      modTotalBuyCost: totalBuyCost ? `$${totalBuyCost}` : 0,
      modFbaFee:
        item.sellPrice && item.sellPrice > 0 ? `$${item.fbaFee} ` : 'N/A',
      modListPrice: item.listPrice > 0 ? `$${item.listPrice}` : 'N/A',
      modProfitMargin: item.sellPrice > 0 ? `${profitMargin}%` : 'N/A',
      modTotalCostTooltip,
      modFbaFeeTooltip: item.sellPrice ? modFbaFeeTooltip : '',
      modProfitTooltip: item.sellPrice ? modProfitTooltip : '',
      modProfitMarginTooltip: item.sellPrice ? modProfitMarginTooltip : '',
      amazonURL,
      googleSearchUrl,
      keepaURL,
    }
  })

  return modifiedFavorites
}
