import React, { useState, useEffect } from 'react'
import Cover from './../../assets/cover.png'
import Google from './../../assets/google.png'
import { IoMdEye } from 'react-icons/io'
import { IoMdEyeOff } from 'react-icons/io'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { SignInSchema } from './validationSchemas'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  googleSignInStart,
  emailSignInStart,
  userError,
} from '../../redux/User/user.actions'

const SignIn = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const { currentUser, userErr } = useSelector((state) => state.user)
  const [isFieldFocused, setIsFieldFocused] = useState({
    email: false,
    password: false,
  })

  useEffect(() => {
    currentUser && navigate('/')
  }, [currentUser, navigate])

  useEffect(() => {
    if (userErr) {
      const timer = setTimeout(() => {
        dispatch(userError(null))
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [userErr])

  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart())
  }

  return (
    <div className='mx-auto border rounded-md max-w-[450px] text-gray-700 py-10 px-4 sm:px-8 mt-10 overflow-hidden'>
      <div
        className='flex items-center justify-center w-full h-20 hover:cursor-pointer'
        onClick={() => navigate('/')}
      >
        <img src={Cover} alt='Logo' className='h-16 w-[76px] mr-1' />
        <h1 className='text-[44px] text-gray-900'>arbithub</h1>
      </div>
      <div
        className={`flex items-center justify-center gap-1.5 h-10 w-full relative bg-red-500 text-sm text-gray-50 my-1 rounded-sm ${userErr ? 'left-0' : '-left-[440px]'} transition-all duration-300`}
      >
        <span
          className={`flex justify-center items-center text-sm font-bold bg-gray-50 text-red-500 h-[18px] w-[18px] rounded-full ${!userErr && 'hidden'}`}
        >
          !
        </span>
        <span>{userErr}</span>
      </div>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={SignInSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          dispatch(emailSignInStart(values))
          setSubmitting(false)
          currentUser && resetForm()
        }}
      >
        {({ values, handleBlur, isSubmitting }) => (
          <Form>
            <div className='relative h-[72px] pt-3'>
              <Field
                id='email'
                name='email'
                type='email'
                className='border rounded hover:border-black w-full h-11 px-2 focus:outline-red-900'
                onFocus={() =>
                  setIsFieldFocused({ ...isFieldFocused, email: true })
                }
                onBlur={(e) => {
                  setIsFieldFocused({ ...isFieldFocused, email: false })
                  handleBlur(e)
                }}
              />
              <label
                htmlFor='email'
                className={`absolute left-2 text-gray-700 cursor-text transition-all duration-300 ${isFieldFocused.email || values.email !== '' ? 'top-0.5 bg-white rounded px-1 text-xs' : 'top-1/2 transform -translate-y-1/2 text-md'} `}
              >
                Email Address
              </label>
              <ErrorMessage
                name='email'
                component='div'
                className='text-xs text-red-500 pl-2'
              />
            </div>
            <div className='relative h-[72px] pt-3'>
              <div
                className='peer absolute text-xl right-2 top-1/2 transform -translate-y-1/2 p-1.5 rounded-full cursor-pointer hover:bg-gray-200'
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <IoMdEye /> : <IoMdEyeOff />}
              </div>
              <Field
                id='password'
                name='password'
                type={showPassword ? 'text' : 'password'}
                className='peer-hover:border-black border rounded hover:border-black w-full h-11 pl-2 pr-12 focus:outline-red-900'
                onFocus={() =>
                  setIsFieldFocused({ ...isFieldFocused, password: true })
                }
                onBlur={(e) => {
                  setIsFieldFocused({ ...isFieldFocused, password: false })
                  handleBlur(e)
                }}
              />

              <label
                htmlFor='password'
                className={`absolute left-2 text-gray-700 cursor-text transition-all duration-300 ${isFieldFocused.password || values.password !== '' ? 'top-0.5 bg-white rounded px-1 text-xs' : 'top-1/2 transform -translate-y-1/2 text-md'} `}
              >
                Password
              </label>
              <ErrorMessage
                name='password'
                component='div'
                className='text-xs text-red-500 pl-2'
              />
            </div>
            <button
              type='submit'
              disabled={isSubmitting}
              className={`text-sm font-semibold hover:bg-blue-600 w-full h-9 rounded mt-5 transition-all ${isSubmitting ? 'bg-gray-200 text-gray-600 shadow-sm' : 'bg-blue-500 text-white shadow-md'}`}
            >
              SIGN IN
            </button>
          </Form>
        )}
      </Formik>
      <div className='flex justify-between mx-1 mt-5 text-sm  text-blue-500'>
        <Link to='/recovery'>
          <span className='text-sm text-blue-500 hover:text-blue-600 underline'>
            Forgot password?
          </span>
        </Link>
        <div className='text-gray-800 '>
          Don&apos;t have an account?
          <Link to='/pricing'>
            <span className='text-blue-500 hover:text-blue-600 ml-1 underline '>
              Sign Up
            </span>
          </Link>
        </div>
      </div>
      <div className='relative my-10 border-t'>
        <span className='absolute bg-white text-gray-500 px-5 -top-3.5 left-1/2 transform -translate-x-1/2'>
          or
        </span>
      </div>
      <button
        type='button'
        onClick={handleGoogleSignIn}
        className='flex items-center justify-center border text-sm text-gray-600 font-semibold shadow-sm hover:bg-gray-50 w-full h-10 rounded transition-colors duration-200'
      >
        <img src={Google} alt='Google' className='h-6 mr-2' />
        Sign In With Google
      </button>
    </div>
  )
}

export default SignIn
