import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { checkout } from '../../Utils'
import { IoCheckmark } from 'react-icons/io5'
import FadeLoader from 'react-spinners/FadeLoader'

function PricingDetails() {
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)
  const [isLoading, setIsLoading] = useState(false)

  const handleFreeTrial = () => {
    if (!currentUser) {
      navigate('/sign-up')
    } else if (
      currentUser &&
      (!currentUser.subscription ||
        (currentUser.subscription &&
          currentUser.subscription.status !== 'active'))
    ) {
      checkout(currentUser.id)
      setIsLoading(true)
    } else {
      navigate('/products')
    }
  }

  return (
    <div className='my-10 min-h-[calc(100vh-450px)] md:min-h-[calc(100vh-380px)] lg:min-h-[calc(100vh-305px)]'>
      {isLoading ? (
        <div className='fixed z-50 left-1/2 top-1/3 transform -translate-x-1/2 -translate-y-1/2'>
          <FadeLoader color='#2E7D32' />
        </div>
      ) : (
        <section className='w-[380px] sm:w-[540px] mx-auto'>
          <header className='text-center'>
            <h2 className='font-bold text-3xl text-blue-950'>
              Simple, competitive pricing
            </h2>
            <h2 className='font-semibold text-md mt-1'>
              Sign up for our 7-day trial
            </h2>
          </header>
          <main className='shadow-lg shadow-gray-400 h-76 sm:h-64 rounded-md py-7 sm:py-10 px-4 my-4'>
            <div className='text-lg flex items-center justify-center gap-1 mb-8 font-semibold'>
              (US)
              <span className='text-4xl text-gray-800 mb-1'>$39.90</span>
              /month
            </div>
            <div className='flex items-center flex-col sm:flex-row gap-8 sm:gap-0'>
              <div className='w-full sm:w-3/5 pl-2'>
                <div className='flex items-center gap-1 text-gray-500 text-[15px] h-7 font-semibold'>
                  <IoCheckmark className='text-green-500 text-sm' />
                  <span>Unlimited arbitrage opportunities</span>
                </div>
                <div className='flex items-center gap-1 text-gray-500 text-[15px] h-7 font-semibold'>
                  <IoCheckmark className='text-green-500 text-sm' />
                  <span>Top 1% profitable products</span>
                </div>
                <div className='flex items-center gap-1 text-gray-500 text-[15px] h-7 font-semibold'>
                  <IoCheckmark className='text-green-500 text-sm' />
                  <span>Selected from over +600M products</span>
                </div>
              </div>
              <div className='flex items-center justify-center w-full sm:w-2/5'>
                {currentUser &&
                currentUser.subscription &&
                currentUser.subscription.planId &&
                currentUser.subscription.status === 'active' ? (
                  <button
                    type='button'
                    onClick={() => navigate('/products')}
                    className='text-xs font-semibold hover:bg-blue-900 w-48 h-10 rounded-full transition-all bg-blue-950 text-white shadow-md'
                  >
                    Already Subscribed
                  </button>
                ) : (
                  <button
                    type='button'
                    onClick={handleFreeTrial}
                    className='text-xs font-semibold hover:bg-blue-900 w-48 h-10 rounded-full transition-all bg-blue-950 text-white shadow-md'
                  >
                    Start My Free Trial
                  </button>
                )}
              </div>
            </div>
          </main>
        </section>
      )}
    </div>
  )
}

export default PricingDetails
