import React, { useState } from 'react';
import Header from '../../components/Header';

const Admin = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [provider, setProvider] = useState(""); // New state for provider
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleSubmit = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select a file before submitting.");
      return;
    }

    if (provider.trim() === "") {
      alert("Please enter a provider.");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("provider", provider); // Include provider in the form data
    selectedFiles.forEach((file) => formData.append("files", file));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/wholesaleProducts/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        alert("Files uploaded successfully!");
        setSelectedFiles([]);
        setProvider(""); // Reset provider input after successful upload
      } else {
        alert("Failed to upload files.");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("An error occurred while uploading the files.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <Header {...props} />
      <div className="flex items-center justify-center bg-gray-100" style={{ height: 'calc(100vh - 4rem)' }}>
        <div className="bg-white rounded-lg shadow-md p-8 max-w-md text-center">
          <h1 className="text-2xl font-semibold mb-4">Upload Wholesale CSV</h1>
          <p className="text-gray-700 mb-2">Drag and drop your CSV here.</p>

          {/* Provider Textbox */}
          <input
            type="text"
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
            placeholder="Enter provider"
            className="mb-4 w-full px-4 py-2 border border-gray-300 rounded"
          />

          <div
            className="max-w-xl"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              const files = Array.from(e.dataTransfer.files);
              handleFileChange({ target: { files } });
            }}
          >
            <label
              className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
            >
              <span className="flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
                <span className="font-medium text-gray-600">
                  <a>Drop files to Attach, or </a>
                  <span className="text-blue-600 underline">browse</span>
                </span>
              </span>
              <input
                type="file"
                name="file_upload"
                className="hidden"
                onChange={handleFileChange}
                multiple
              />
            </label>
          </div>

          {/* Display selected file names */}
          <div className="mt-4">
            {selectedFiles.length > 0 && (
              <ul className="text-gray-700">
                {selectedFiles.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            )}
          </div>

          {/* Submit button */}
          <button
            onClick={handleSubmit}
            disabled={uploading}
            className={`mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded ${uploading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {uploading ? "Uploading..." : "Submit Files"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Admin;
