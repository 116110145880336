import { FaXTwitter, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa6'

export const socialMediaLinks = [
  {
    icon: <FaXTwitter className='social-icon' />,
    link: 'https://twitter.com/arbithub',
    label: 'X',
    color: '#242424',
  },
  {
    icon: <FaInstagram className='social-icon' />,
    link: 'https://www.instagram.com/arbithub',
    label: 'Instagram',
    color: '#F08080',
  },
  {
    icon: <FaLinkedin className='social-icon' />,
    link: 'https://www.linkedin.com/company/arbithub',
    label: 'Linkedin',
    color: '#0077b5',
  },
  {
    icon: <FaYoutube className='social-icon' />,
    link: 'https://www.youtube.com/@arbithub',
    label: 'Youtube',
    color: 'red',
  },
]
