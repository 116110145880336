import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IoMailOutline } from 'react-icons/io5'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { contactUsSchema } from '../../components/Authentication/validationSchemas'
import { socialMediaLinks } from '../../components/Footer/data'
import FadeLoader from 'react-spinners/FadeLoader'
import emailjs from '@emailjs/browser'
import Modal from './Modal'

const index = () => {
  const formRef = useRef()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(null)
  const [isFieldFocused, setIsFieldFocused] = useState({
    firstName: false,
    lastName: false,
    email: false,
    reason: true,
    message: false,
  })

  const sendEmail = (values, setSubmitting, resetForm) => {
    emailjs
      .sendForm('service_ap50n78', 'template_s5nom29', formRef.current, {
        publicKey: 'q4MT5_O8SngGMvQx2',
      })
      .then(
        () => {
          setOpenModal('success')
          resetForm()
          setSubmitting(false)
          setLoading(false)
        },
        (error) => {
          console.error(error.text)
          setOpenModal('failed')
          setSubmitting(false)
          setLoading(false)
        }
      )

    emailjs
      .send(
        'service_064b53u',
        'template_45otf2r',
        {
          to_email: values.email,
          to_name: values.firstName + ' ' + values.lastName,
        },
        'VS1ACg9fR3xvt25_A'
      )
      .then(
        () => {},
        (error) => {
          console.error(error.text)
        }
      )
  }

  return (
    <div className='p-2 min-h-[calc(100vh-450px)] md:min-h-[calc(100vh-380px)] lg:min-h-[calc(100vh-305px)] text-gray-700 py-12'>
      {loading && (
        <div className='fixed inset-0 z-50 flex justify-center items-center '>
          <FadeLoader color='#2E7D32' />
        </div>
      )}
      <div className='mx-auto w-full max-w-[1000px] min-h-[420px] flex flex-col md:flex-row items-center shadow-md border rounded-lg overflow-hidden'>
        <div className='w-full min-w-64 md:w-1/4 bg-blue-950 text-gray-100 h-[320px] md:h-[420px] p-3'>
          <h1 className='text-center text-2xl font-semibold'>Contact Detail</h1>
          <div className='flex flex-col items-center justify-around h-full gap-1 text-sm'>
            <div className='text-center'>
              <h2 className='text-center text-[16px] mb-3'>
                Need to get in touch with us?
              </h2>
              Fill out the form with your inquiry or find our email address
              below. You can also connect with us on social media.
            </div>

            <div className='flex flex-col items-center gap-1'>
              <IoMailOutline className='text-2xl' />
              <p>Email: help@arbithub.com</p>
            </div>
            <section className='flex justify-center gap-5 text-2xl mb-5'>
              {socialMediaLinks.map((item, index) => (
                <a
                  style={{
                    color: item.color === '#242424' ? 'lightgray' : item.color,
                  }}
                  className='hover:scale-110 transition-scale duration-300'
                  key={index}
                  href={item.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  aria-label={item.label}
                >
                  {item.icon}
                </a>
              ))}
            </section>
          </div>
        </div>
        <div className='w-full md:w-3/4 h-[590px] sm:h-[420px] p-3'>
          {<Modal {...{ openModal, setOpenModal, navigate }} />}
          <h1 className='text-center text-2xl font-semibold text-gray-800 my-2 md:my-0'>
            Send Us A Message
          </h1>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              reason: '',
              message: '',
            }}
            validationSchema={contactUsSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setLoading(true)
              sendEmail(values, setSubmitting, resetForm)
            }}
          >
            {({ values, handleBlur, isSubmitting }) => (
              <Form ref={formRef}>
                <div className='flex flex-col sm:flex-row gap-3'>
                  <div className='relative h-[72px] pt-3 w-full'>
                    <Field
                      id='firstName'
                      name='firstName'
                      type='text'
                      className='border rounded hover:border-black w-full h-11 px-2 focus:outline-blue-900'
                      onFocus={() =>
                        setIsFieldFocused({
                          ...isFieldFocused,
                          firstName: true,
                        })
                      }
                      onBlur={(e) => {
                        setIsFieldFocused({
                          ...isFieldFocused,
                          firstName: false,
                        })
                        handleBlur(e)
                      }}
                    />
                    <label
                      htmlFor='firstName'
                      className={`absolute left-2 text-gray-700 cursor-text transition-all duration-300 ${isFieldFocused.firstName || values.firstName !== '' ? 'top-0.5 bg-white rounded px-1 text-xs' : 'top-1/2 transform -translate-y-1/2 text-md'} `}
                    >
                      First Name
                    </label>
                    <ErrorMessage
                      name='firstName'
                      component='div'
                      className='text-xs text-red-500 pl-2'
                    />
                  </div>
                  <div className='relative h-[72px] pt-3 w-full'>
                    <Field
                      id='lastName'
                      name='lastName'
                      type='text'
                      className='border rounded hover:border-black w-full h-11 px-2 focus:outline-blue-900'
                      onFocus={() =>
                        setIsFieldFocused({ ...isFieldFocused, lastName: true })
                      }
                      onBlur={(e) => {
                        setIsFieldFocused({
                          ...isFieldFocused,
                          lastName: false,
                        })
                        handleBlur(e)
                      }}
                    />
                    <label
                      htmlFor='lastName'
                      className={`absolute left-2 text-gray-700 cursor-text transition-all duration-300 ${isFieldFocused.lastName || values.lastName !== '' ? 'top-0.5 bg-white rounded px-1 text-xs' : 'top-1/2 transform -translate-y-1/2 text-md'} `}
                    >
                      Last Name
                    </label>
                    <ErrorMessage
                      name='lastName'
                      component='div'
                      className='text-xs text-red-500 pl-2'
                    />
                  </div>
                </div>
                <div className='flex flex-col sm:flex-row gap-3'>
                  <div className='relative h-[72px] pt-3 w-full'>
                    <Field
                      id='email'
                      name='email'
                      type='email'
                      className='border rounded hover:border-black w-full h-11 px-2 focus:outline-blue-900'
                      onFocus={() =>
                        setIsFieldFocused({ ...isFieldFocused, email: true })
                      }
                      onBlur={(e) => {
                        setIsFieldFocused({ ...isFieldFocused, email: false })
                        handleBlur(e)
                      }}
                    />
                    <label
                      htmlFor='email'
                      className={`absolute left-2 text-gray-700 cursor-text transition-all duration-300 ${isFieldFocused.email || values.email !== '' ? 'top-0.5 bg-white rounded px-1 text-xs' : 'top-1/2 transform -translate-y-1/2 text-md'} `}
                    >
                      Email Address
                    </label>
                    <ErrorMessage
                      name='email'
                      component='div'
                      className='text-xs text-red-500 pl-2'
                    />
                  </div>
                  <div className='relative h-[72px] pt-3 w-full'>
                    <Field
                      id='reason'
                      name='reason'
                      as='select'
                      style={{ resize: 'none' }}
                      className='border rounded hover:border-black w-full h-11 px-2 focus:outline-blue-900'
                    >
                      <option disabled value=''>
                        Select a topic
                      </option>
                      <option value='feedback'>Share your feedback</option>
                      <option value='complaint'>File a complaint</option>
                      <option value='cancelSubscription'>
                        Cancel your subscription
                      </option>
                      <option value='requestDemo'>Request a demo</option>
                    </Field>
                    <label
                      htmlFor='reason'
                      className={`absolute  left-2 text-gray-700 cursor-text transition-all duration-300 ${isFieldFocused.reason || values.reason !== '' ? 'top-0.5 bg-white rounded px-1 text-xs' : 'top-1/2 transform -translate-y-1/2 text-md'} `}
                    >
                      Reason For Contact
                    </label>
                    <ErrorMessage
                      name='reason'
                      component='div'
                      className='text-xs text-red-500 pl-2'
                    />
                  </div>
                </div>
                <div className='relative h-[150px] pt-3'>
                  <Field
                    id='message'
                    name='message'
                    as='textarea'
                    style={{ resize: 'none' }}
                    className='border rounded hover:border-black w-full h-32 p-2 focus:outline-blue-900'
                    onFocus={() =>
                      setIsFieldFocused({ ...isFieldFocused, message: true })
                    }
                    onBlur={(e) => {
                      setIsFieldFocused({ ...isFieldFocused, message: false })
                      handleBlur(e)
                    }}
                  />
                  <label
                    htmlFor='message'
                    className={`absolute left-2 text-gray-700 cursor-text transition-all duration-300 ${isFieldFocused.message || values.message !== '' ? 'top-0.5 bg-white rounded px-1 text-xs' : 'top-5 text-md'} `}
                  >
                    Your Message
                  </label>
                  <ErrorMessage
                    name='message'
                    component='div'
                    className='text-xs text-red-500 pl-2'
                  />
                </div>
                <div className='flex justify-center mt-3'>
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className={`text-sm font-semibold hover:bg-blue-900 w-44 h-9 rounded-full transition-all ${isSubmitting ? 'bg-gray-200 text-gray-600 shadow-sm' : 'bg-blue-950 text-white shadow-md'}`}
                  >
                    SUBMIT
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default index
