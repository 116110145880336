const productsTypes = {
  PRODUCTS_READ_REQUEST: 'PRODUCTS_READ_REQUEST',
  PRODUCTS_READ_SUCCESS: 'PRODUCTS_READ_SUCCESS',
  PRODUCTS_READ_FAILURE: 'PRODUCTS_READ_FAILURE',

  PRODUCTS_DELETE_REQUEST: 'PRODUCTS_DELETE_REQUEST',
  PRODUCTS_DELETE_SUCCESS: 'PRODUCTS_DELETE_SUCCESS',
  PRODUCTS_DELETE_FAILURE: 'PRODUCTS_DELETE_FAILURE',
}

export default productsTypes
