import Papa from 'papaparse'
import * as XLSX from 'xlsx'

const exportData = (data) => {
  return data?.map((item) => ({
    Name: item.name,
    Category: item.category,
    'Buy Market': item.modBuyMarket,
    'Buy Price': parseFloat(item.modBuyPrice.replace('$', '')),
    'Sell Market': item.sellMarket,
    'Sell Price': item.sellPrice || 'out of stock',
    ROI: item.profitRatio || 'N/A',
    Profit: item.sellPrice > 0 ? item.profit : 'N/A',
    'Best Seller Rank': item.sellRank,
    'Unit Sell Per Month': item.unitSales > 0 ? item.unitSales : 'unknown',
    'Total Buy Cost': parseFloat(item.modTotalBuyCost.replace('$', '')),
    'Total Amazon Fees': item.sellPrice ? item.fbaFee : 'N/A',
    'List Price': item.listPrice > 0 ? item.listPrice : 'N/A',
    'Profit Margin':
      item.sellPrice > 0
        ? parseFloat(item.modProfitMargin.replace('$', ''))
        : 'N/A',
    'Number Of Sellers': item.numOfSellers,
    'Estimated Tax': parseFloat(item.modEstimatedTax.replace('$', '')),
  }))
}

export const exportToCSV = (data) => {
  const expoData = exportData(data)
  const csv = Papa.unparse(expoData)
  const csvBlob = new Blob([csv], { type: 'text/csv' })
  const csvUrl = URL.createObjectURL(csvBlob)
  const link = document.createElement('a')
  link.href = csvUrl
  link.setAttribute('download', 'arbithub_export_file.csv')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const exportToExcel = (data) => {
  const expoData = exportData(data)
  const worksheet = XLSX.utils.json_to_sheet(expoData)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, 'arbithub_export_file.xlsx')
}
