import * as React from 'react'
import Tooltip from '../Shared/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { searchButtons } from '../Views/data'

const SliderCard = ({ item }) => {
  const tableData = [
    {
      title: 'ROI',
      value: item?.modROI,
      tooltipText: '',
    },
    {
      title: 'Profit',
      value: item?.modProfit,
      tooltipText: item?.modProfitTooltip,
    },
    {
      title: 'Total Buy Cost',
      value: item?.modTotalBuyCost,
      tooltipText: item?.modTotalCostTooltip,
    },
    {
      title: 'Unit Sales Per Month',
      value: item?.modUnitSales,
      tooltipText: '',
    },
    {
      title: 'Best Seller Rank',
      value: item?.modSellRank,
      tooltipText: '',
    },
    {
      title: 'Total Amazon Fees',
      value: item?.modFbaFee,
      tooltipText: item?.modFbaFeeTooltip,
    },
  ]

  return (
    <div className='w-[300px] h-[390px] bg-white text-gray-800 text-center rounded-md p-2.5 shadow-md '>
      <div>
        <p className='text-xs font-semibold mb-0 overflow-hidden line-clamp-1 cursor-pointer'>
          {item?.category}
        </p>
        <p className='peer relative text-xs font-semibold mb-0 overflow-hidden line-clamp-1 cursor-pointer'>
          {item?.name}
        </p>
        <span className='peer-hover:visible peer-hover:delay-700 invisible absolute top-12 left-1/2 transform -translate-x-1/2 rounded-md shadow-md px-2 py-1 bg-gray-800 !text-xs text-white w-max max-w-64  z-10 transition-visible duration-300'>
          {item?.name}
        </span>
      </div>
      <div className='flex justify-center items-center mt-1 mb-3'>
        <img
          className='h-[100px] object-contain'
          src={item?.modProductThumbnail}
          alt='product-image'
        />
      </div>
      <div className='flex gap-3 mt-2.5 px-1'>
        <div className='w-1/2 h-8 border border-solid border-gray-400 rounded-md relative cursor-pointer shadow-md hover:shadow-gray-400'>
          <p className='absolute font-semibold text-[10px] -top-[12px] px-0.5 left-1/2 transform -translate-x-1/2 bg-white whitespace-nowrap'>
            Marketplace to Buy
          </p>
          <p className='mx-auto mt-1.5 text-[10px] font-bold'>
            {item?.modBuyPrice} in {item?.modBuyMarket}
          </p>
        </div>
        <div className='w-1/2 h-8 border border-solid border-gray-400 rounded-md relative cursor-pointer shadow-md hover:shadow-gray-400'>
          <p className='absolute font-semibold text-[10px] -top-[12px] px-0.5 left-1/2 transform -translate-x-1/2 bg-white whitespace-nowrap'>
            Marketplace to Sell
          </p>
          <p className='mx-auto mt-1.5 text-[10px] font-bold'>
            {item?.modSellPrice} in {item?.sellMarket}
          </p>
        </div>
      </div>
      <div className='flex justify-evenly mt-2.5'>
        {searchButtons.map((button) => (
          <a
            key={button.tooltipText}
            href={item[button.URL]}
            target='_blank'
            rel='noopener noreferrer'
            className='group relative flex justify-center items-center text-white bg-sky-600 rounded-lg text-md w-14 h-6 cursor-pointer shadow-md hover:bg-[#f39200] active:scale-95 '
          >
            <FontAwesomeIcon icon={button.icon} />
            <span className='group-hover:visible group-hover:delay-700 invisible absolute bottom-8 rounded-md shadow-md px-2 py-1 bg-gray-800 text-xs text-white w-max max-w-64  z-10 transition-visible duration-300'>
              {button.tooltipText}
            </span>
          </a>
        ))}
      </div>
      <div className='rounded-md overflow-hidden shadow-md shadow-gray-400 mt-2.5 mx-2'>
        <table className='border-collapse w-full'>
          <tbody>
            {tableData.map((item, index) => (
              <Tooltip key={item.title} tooltipText={item.tooltipText}>
                <tr
                  className={` ${index % 2 ? ' bg-blue-50' : 'bg-blue-300'} ${index === 0 && '!font-bold !text-sm'} text-left text-xs h-6 font-semibold hover:bg-gray-300 cursor-pointer`}
                >
                  <td className='border-r px-1.5'>{item.title}</td>
                  <td className='p-1 line-clamp-1'>{item.value}</td>
                </tr>
              </Tooltip>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SliderCard
