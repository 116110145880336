import React from 'react'
const Skeleton = () => {
  return (
    <>
      <div className='w-[300px] h-[370px] flex flex-col items-center gap-4 p-2.5 bg-[#fcfcfc58] rounded-md'>
        <div className='w-[120px] h-[10px] rounded-sm bg-gray-300 animate-pulse'></div>
        <div className='w-[250px] h-[10px] rounded-sm bg-gray-300 animate-pulse'></div>
        <div className='w-[100px] h-[90px] rounded-sm bg-gray-300 animate-pulse'></div>
        <div className='flex gap-2.5'>
          <div className='w-[130px] h-[35px] rounded-md bg-gray-300 animate-pulse'></div>
          <div className='w-[130px] h-[35px] rounded-md bg-gray-300 animate-pulse'></div>
        </div>
        <div className='flex gap-5'>
          <div className='w-[50px] h-[30px] rounded-md bg-gray-300 animate-pulse'></div>
          <div className='w-[50px] h-[30px] rounded-md bg-gray-300 animate-pulse'></div>
          <div className='w-[50px] h-[30px] rounded-md bg-gray-300 animate-pulse'></div>
        </div>
        <div className='w-[230px] h-[110px] rounded-sm bg-gray-300 animate-pulse'></div>
      </div>
    </>
  )
}

export default Skeleton
