// eslint-disable-next-line import/named
import { call, put, takeEvery } from 'redux-saga/effects'
import productsTypes from './products.types'
import { fetchProducts, removeProduct } from './products.api'

export function* readProductsSaga(action) {
  try {
    const query = action.payload
    const response = yield call(fetchProducts, query)

    yield put({
      type: productsTypes.PRODUCTS_READ_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: productsTypes.PRODUCTS_READ_FAILURE,
      payload: error,
    })
  }
}

export function* deleteProductSaga(action) {
  try {
    yield call(removeProduct, action.payload)
    yield put({
      type: productsTypes.PRODUCTS_DELETE_SUCCESS,
      payload: action.payload,
    })
  } catch (error) {
    yield put({
      type: productsTypes.PRODUCTS_DELETE_FAILURE,
      payload: error,
    })
  }
}

export default function* productsSaga() {
  yield takeEvery(productsTypes.PRODUCTS_READ_REQUEST, readProductsSaga)
  yield takeEvery(productsTypes.PRODUCTS_DELETE_REQUEST, deleteProductSaga)
}
