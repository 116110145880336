import { useState, useRef } from 'react'
import { FaRegWindowClose } from 'react-icons/fa'

const ImageModal = ({ open, setOpen, image }) => {
  const [size, setSize] = useState(350)
  const ref = useRef(null)

  const handleContainerClick = (event) => {
    event.stopPropagation()
  }

  const handleClickOutside = (event) => {
    if (!ref.current.contains(event.target)) {
      setOpen(false)
    }
  }

  return (
    <>
      {open && (
        <div
          className='fixed z-20 inset-0 overflow-y-auto backdrop-brightness-75 flex items-center justify-center'
          onClick={handleClickOutside}
        >
          <div
            className={`relative  bg-white shadow-xl rounded-md overflow-hidden`}
            style={{ height: size, width: size }}
            ref={ref}
            onClick={handleContainerClick}
          >
            <FaRegWindowClose
              onClick={() => setOpen(false)}
              className='absolute right-[5px] top-1 size-6 cursor-pointer opacity-80 hover:opacity-100'
            />
            <img
              src={image}
              alt='Product-image'
              onClick={() => setSize(size === 350 ? '80vh' : 350)}
              className={`object-contain ${size === 400 ? 'cursor-zoom-in' : 'cursor-zoom-out'} max-h-[600px] h-full w-full object-contain`}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ImageModal
