import React from 'react'
import PricingDetails from '../../components/Pricing'

const Pricing = () => {
  return (
    <div>
      <PricingDetails />
    </div>
  )
}

export default Pricing
