import React, { useEffect, useState } from 'react'
import CardViewItems from './CardViewItems'
import { SlArrowUp } from 'react-icons/sl'

const CardView = ({ data, page, containerRef }) => {
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.scrollY > 300 ||
        (containerRef?.current && containerRef.current.scrollTop > 300)
      ) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    if (containerRef?.current) {
      containerRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (containerRef?.current) {
        containerRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [containerRef])

  const scrollToTop = () => {
    if (containerRef?.current) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <>
      <div className='flex flex-col gap-4'>
        {data.map((item, index) => (
          <CardViewItems key={index} {...{ item, page }} />
        ))}
      </div>

      <div
        className={`fixed h-0 ${showButton && 'h-[70px]'} -bottom-3 right-2 lg:right-10 flex flex-col text-center items-center shadow-md w-min  px-2 py-1 text-xs rounded-t-md bg-gray-600 text-white cursor-pointer hover:bg-gray-700 active:scale-95 transition-visible duration-300`}
        onClick={scrollToTop}
      >
        <SlArrowUp className='text-lg' />
        RETURN TO TOP
      </div>
    </>
  )
}

export default CardView
