import userTypes from './user.types'

const INITIAL_STATE = {
  currentUser: null,
  resetPasswordSuccess: false,
  userErr: null,
  updateProfileSuccess: false,
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          signInMethod: action.payload.signInMethod,
          emailVerified: action.payload.emailVerified,
        },
        userErr: null,
      }
    case userTypes.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          emailVerified: action.payload.emailVerified,
        },
        updateProfileSuccess: true,
        userErr: null,
      }
    case userTypes.RESET_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileSuccess: false,
      }
    case userTypes.USER_ERROR:
      return {
        ...state,
        userErr: action.payload,
      }
    case userTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: action.payload,
      }
    case userTypes.RESET_USER_STATE:
    case userTypes.SIGN_OUT_USER_SUCESS:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    default:
      return state
  }
}

export default userReducer
