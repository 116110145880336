import React from 'react'
// import { Link } from 'react-router-dom'
import './styless.css'

const Cancel = () => {
  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white rounded-lg shadow-md p-8 max-w-md text-center'>
        <h1 className='text-2xl font-semibold text-red-600 mb-4'>
          Payment Failed
        </h1>
        <p className='text-gray-700 mb-2'>
          Unfortunately, your payment could not be processed. Please try again.
        </p>

        <div className='mb-4'>
          <h2 className='text-lg font-medium'>What to do next:</h2>
          <ul className='list-disc list-inside text-gray-600'>
            <li>Check your payment details and try again.</li>
            <li>Ensure that your payment method is valid.</li>
          </ul>
        </div>
        <button
          onClick={() => (window.location.href = '/')}
          className='mt-2 w-full bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 rounded'
        >
          Return to Homepage
        </button>
      </div>
    </div>
  )
}

export default Cancel
