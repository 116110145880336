const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT

export const fetchProducts = async ({
  filterOptions,
  sortBy,
  currentPage,
  pageSize,
  productType,
}) => {
  console.log(filterOptions)
  const params = new URLSearchParams({
    filterOptions: JSON.stringify(filterOptions),
    sortBy: JSON.stringify(sortBy),
    currentPage,
    pageSize,
  })

  try {
    var url
    if (productType == 'wholesale') {
      url = `${ENDPOINT}/api/v1/wholesaleProducts?${params}`
    } else {
      url = `${ENDPOINT}/api/v1/onlineArbitrageProducts?${params}`
    }

    const response = await fetch(url)

    if (response.status === 404) {
      // No products found, return empty data
      return {
        data: [],
        isLastPage: true,
        totalCount: 0,
      }
    }

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`)
    }

    const { data, isLastPage, totalCount } = await response.json()
    return {
      data,
      isLastPage,
      totalCount,
    }
  } catch (error) {
    throw new Error(`Failed to fetch products: ${error.message}`)
  }
}

export const removeProduct = async (id) => {
  try {
    const response = await fetch(
      `${ENDPOINT}/api/v1/onlineArbitrageProducts/${id}`,
      {
        method: 'DELETE',
      }
    )

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`)
    }

    return true
  } catch (error) {
    throw new Error(`Error removing product: ${error.message}`)
  }
}
