import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { BiUser } from 'react-icons/bi'
import { FiMenu } from 'react-icons/fi'
import Cover from './../../assets/cover.png'
import UserMenu from './UserMenu'
import { readFavorites } from '../../redux/Favorites/favorites.actions'

const Header = () => {
  const favorites = useSelector((state) => state.favoritesData.favorites)
  const currentUser = useSelector((state) => state.user.currentUser)
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false)
  const [openUserMenu, setOpenUserMenu] = useState(false)
  const dispatch = useDispatch()
  const totalFavorites = favorites?.length
  const displayName = currentUser?.displayName
    ? currentUser?.displayName.charAt(0).toUpperCase() +
      currentUser?.displayName.slice(1).toLowerCase()
    : ''

  const navMenuItems = [
    { name: 'Pricing', path: '/pricing' },
    currentUser && { name: 'Online Arbitrage', path: '/products' },
    currentUser && { name: 'Wholesale', path: '/wholesale' },
    currentUser && { name: 'Favorites', path: '/favorites' },
    { name: 'Contact Us', path: '/contact-us' },
    !currentUser && { name: 'Free Trial', path: '/pricing' },
    !currentUser && { name: 'Login', path: '/login' },
    currentUser?.userRoles?.includes('admin') && { name: 'Admin', path: '/admin' },
  ].filter(Boolean)

  useEffect(() => {
    dispatch(readFavorites(currentUser?.id))
  }, [currentUser])

  return (
    <header className='bg-[#ececec] min-h-16 shadow-md'>
      <div className='flex justify-between items-center h-16 px-3 sm:px-12'>
        <div
          className='flex items-center justify-center lg:hidden hover:border hover:bg-[#dedede] active:bg-[#cccccc] rounded-full h-12 w-12 cursor-pointer transition-colors duration-300'
          onClick={() => setOpenBurgerMenu(!openBurgerMenu)}
        >
          <FiMenu className='text-3xl text-gray-700' />
        </div>
        <Link to='/'>
          <div className='group flex items-center'>
            <img
              src={Cover}
              alt='Logo'
              className='h-9 w-11 sm:h-12 sm:w-14 sm:mr-1'
            />
            <h1 className='text-2xl sm:text-4xl text-gray-800 group-hover:text-gray-950'>
              arbithub
            </h1>
          </div>
        </Link>
        <nav className='flex items-center gap-10'>
          <ul className='hidden lg:flex font-semibold'>
            {navMenuItems.map((item) => (
              <li key={item.name}>
                <Link to={item.path}>
                  <div className='h-16 px-4 flex items-center min-w-fit cursor-pointer text-gray-700 border-2 border-transparent hover:border-b-red-500 hover:text-gray-900'>
                    {item.name}
                    {item.name === 'Favorites' && totalFavorites > 0 && (
                      <span className='relative bottom-[9px] right-[2px] text-[10px] text-white bg-[#c05767] h-[14px] pl-[4px] pr-[5px] rounded-full'>
                        {totalFavorites}
                      </span>
                    )}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          {currentUser && (
            <div
              className='relative group flex items-center cursor-pointer h-16'
              onMouseEnter={() => setOpenUserMenu(true)}
              onMouseLeave={() => setOpenUserMenu(false)}
            >
              <div className='text-xs font-semibold mr-2 text-gray-800 group-hover:text-black max-w-32 text-right line-clamp-1'>
                {displayName && `Hi, ${displayName}`}
              </div>
              {currentUser?.photoURL ? (
                <img
                  src={currentUser?.photoURL}
                  alt='Profile Photo'
                  className='rounded-full w-7 h-7 object-cover ring-1 ring-offset-1 ring-gray-400'
                />
              ) : (
                <BiUser className='text-xl text-gray-800 group-hover:text-black' />
              )}

              <UserMenu {...{ openUserMenu, setOpenUserMenu }} />
            </div>
          )}
        </nav>
      </div>
      <div
        className={`flex lg:hidden justify-center items-center bg-[#e4e4e4] overflow-hidden
        ${openBurgerMenu ? 'h-36 opacity-100 border-2 border-[#e4e4e4] border-t-[#dddddd]' : 'opacity-0 h-0'} transition-all duration-300`}
      >
        <ul className='text-gray-700 font-semibold flex flex-col justify-center items-center gap-2'>
          {navMenuItems.map((item) => (
            <li
              key={item.name}
              className={`hover:text-black ${item.name === 'Favorites' && totalFavorites > 0 && 'ml-3'}`}
            >
              <Link to={item.path} onClick={() => setOpenBurgerMenu(false)}>
                {item.name}
                {item.name === 'Favorites' && totalFavorites > 0 && (
                  <span className='relative bottom-[10px] right-[2px] text-[10px] text-white bg-[#c05767] h-[14px] px-1 rounded-full'>
                    {totalFavorites}
                  </span>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  )
}

export default Header
