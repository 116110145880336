import React, { useEffect } from 'react'
import './styles.scss'
import { useSelector } from 'react-redux'
import Alert from 'react-bootstrap/Alert'
import { useState } from 'react'
import { sendEmailVerification, isUserVerified } from '../../firebase/utils'

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
})

const VerificationToolbar = () => {
  const [show, setShow] = useState(true)
  const [emailSent, setEmailSent] = useState(false)
  const [verified, setVerified] = useState(true)
  const [loading, setLoading] = useState(false)
  const { currentUser } = useSelector(mapState)

  useEffect(() => {
    if (currentUser) {
      isUserVerified(currentUser)
        .then((isVerified) => {
          setVerified(isVerified)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [currentUser])

  useEffect(() => {
    if (emailSent) {
      const delayFunction = () => {
        setTimeout(() => {
          setShow(false)
        }, 3000) // Replace 1000 with the desired delay in milliseconds (1 second in this example)
      }
      delayFunction()
    }
  }, [emailSent])

  const verify = async () => {
    setLoading(true)
    sendEmailVerification(currentUser)
    setLoading(false)
    setEmailSent(true)
  }

  if (show && currentUser && !verified) {
    return (
      <div className='local-bootstrap verification-alert'>
        {!emailSent ? (
          <Alert
            key='warning'
            variant='warning'
            onClose={() => setShow(false)}
            dismissible
            transition={true}
          >
            Your email has not been verified yet!{' '}
            {!loading ? (
              <Alert.Link onClick={verify}>Verify now</Alert.Link>
            ) : (
              <>Sending verification link...</>
            )}
          </Alert>
        ) : (
          <Alert
            key='warning'
            variant='warning'
            onClose={() => setShow(false)}
            dismissible
            transition={true}
          >
            Thank you, email verification email sent.
          </Alert>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default VerificationToolbar
