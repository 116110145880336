import React, { useEffect, useState  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SettingsForm from './SettingsForm'
import { subscriptionCancel } from '../../Utils'
import {
  userError,
  resetUpdateProfileSuccess,
} from '../../redux/User/user.actions'

const Settings = () => {
  const dispatch = useDispatch()
  const { currentUser, updateProfileSuccess, userErr } = useSelector(
    (state) => state.user
  )

  const [showModal, setShowModal] = useState(false)
  const [showSubscriptionCancelledModal, setShowSubscriptionCancelledModal] = useState(false)

  useEffect(() => {
    if (userErr) {
      const timer = setTimeout(() => {
        dispatch(userError(null))
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [userErr])

  useEffect(() => {
    if (updateProfileSuccess) {
      const timer = setTimeout(() => {
        dispatch(resetUpdateProfileSuccess())
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [updateProfileSuccess])

  const handleCancelSubscription = () => {
    setShowModal(true)
  }

  const confirmCancelSubscription = () => {
    setShowModal(false)
    subscriptionCancel(currentUser.subscription.subscriptionId, currentUser.id)
    setShowSubscriptionCancelledModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeShowSubscriptionCancelledModal = () => {
    setShowSubscriptionCancelledModal(false);
    window.location.reload();
  }

  const isCurrentUserSubscribed = (user) => {
    if (
      user &&
      user.subscription &&
      user.subscription.planId &&
      user.subscription.planEndDate
    ) {

      if (user.subscription.planId.length === 0) {
        return false
      }

      if (user.subscription.status === "cancelled"){
        return false
      }

      if (user.subscription.planEndDate === 0) {
        return false
      }
      const endDate = new Date(user.subscription.planEndDate)
      const currentDate = new Date()

      if (currentDate > endDate) {
        return false
      }

      return true
    } else {
      return false
    }
  }

  const formFields = [
    { fieldName: 'displayName', label: 'Full Name', type: 'text' },
    { fieldName: 'photoURL', label: 'Profile Picture', type: 'text' },
    { fieldName: 'email', label: 'Email Address', type: 'email' },
    {
      fieldName: 'currentPassword',
      label: 'Current Password',
      type: 'password',
    },
  ]

  return (
    <div className='mx-auto border rounded-md max-w-[450px] text-gray-700 py-5 px-4 sm:px-8 my-7 overflow-hidden'>
      <div className='text-center text-xl font-semibold'>Account Settings</div>
      <div className='flex text-center justify-center items-center mx-auto mt-5 mb-2 ring-2 ring-offset-1 ring-blue-300 rounded-full w-24 h-24 bg-orange-600'>
        {currentUser?.photoURL ? (
          <img
            src={currentUser?.photoURL}
            alt='Profile Photo'
            className='rounded-full w-full h-full object-cover'
          />
        ) : (
          <span className='text-[48px] font-semibold text-white'>
            {currentUser?.displayName.charAt(0).toUpperCase()}
          </span>
        )}
      </div>
      <div
        className={`flex items-center justify-center gap-1.5 h-10 w-full relative ${userErr && 'bg-red-500'} ${updateProfileSuccess && 'bg-green-500'} text-sm text-gray-50 mb-1 rounded-sm -left-[420px] ${(userErr || updateProfileSuccess) && 'left-0'} transition-all duration-300`}
      >
        <span
          className={`flex justify-center items-center text-sm font-bold bg-gray-50 ${userErr && 'text-red-500'} ${updateProfileSuccess && 'text-green-500'} h-[18px] w-[18px] rounded-full ${!userErr && !updateProfileSuccess && 'hidden'}`}
        >
          {userErr ? '!' : '✓'}
        </span>
        <span>
          {userErr ||
            (updateProfileSuccess &&
              'Your profile has been successfully updated!')}
        </span>
      </div>
      {formFields.map((formField) => (
        <SettingsForm
          key={formField.fieldName}
          {...{ currentUser, formField }}
        />
      ))}

    
      <div className='flex items-center mt-5'>
         { isCurrentUserSubscribed(currentUser) &&
          <button
            type='button'
            className='text-sm bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none'
            onClick={handleCancelSubscription}
          >
            Cancel Subscription
          </button>
        }
        {
          ((currentUser && currentUser.subscription && currentUser.subscription.status === "cancelled")) &&
            <button
              type='button'
              className='text-sm bg-gray-400 text-white py-2 px-4 rounded-md cursor-not-allowed opacity-50'
              disabled
            >
              Subscription Cancelled
            </button>    
        }
        </div>
      
      {showModal && (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50'>
          <div className='bg-white rounded-lg p-6 w-[400px]'>
            <h2 className='text-lg font-semibold text-center'>
              Are you sure you want to cancel your subscription?
            </h2>
            <div className='flex justify-center gap-4 mt-6'>
              <button
                className='bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600'
                onClick={confirmCancelSubscription}
              >
                Yes, Cancel
              </button>
              <button
                className='bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400'
                onClick={closeModal}
              >
                No, Go Back
              </button>
            </div>
          </div>
        </div>
      )}

      {showSubscriptionCancelledModal &&(
          <div className='fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50'>
          <div className='bg-white rounded-lg p-6 w-[400px]'>
            <h2 className='text-lg font-semibold text-center'>
              Subscription Canceled
            </h2>

            <p className='text-center mt-4'>
              Your subscription has been successfully canceled. You can continue using the service until{' '}
              <span className='text-blue-500 font-semibold'>{currentUser?.subscription?.planEndDate}</span>. No further charges will be applied.
            </p>
            <div className='flex justify-center gap-4 mt-6'>
              <button
                className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600'
                onClick={closeShowSubscriptionCancelledModal}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default Settings
