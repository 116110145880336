import favoritesTypes from './favorites.types'

const initialState = {
  loading: false,
  error: null,
  favorites: [],
}

const favoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case favoritesTypes.FAVORITES_READ_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case favoritesTypes.FAVORITES_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        favorites: action.payload,
      }
    case favoritesTypes.FAVORITES_READ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case favoritesTypes.FAVORITES_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case favoritesTypes.FAVORITES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        favorites: [...state.favorites, action.payload],
      }
    case favoritesTypes.FAVORITES_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case favoritesTypes.FAVORITES_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case favoritesTypes.FAVORITES_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        favorites: state.favorites.filter(
          (favorite) => favorite.UPC !== action.payload.UPC
        ),
      }
    case favoritesTypes.FAVORITES_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default favoritesReducer
