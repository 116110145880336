// eslint-disable-next-line import/named
import { call, put, takeEvery } from 'redux-saga/effects'
import favoritesTypes from './favorites.types'
import { fetchFavorites, addFavorite, removeFavorite } from './favorites.api'
import { readFavorites } from './favorites.actions'

export function* readFavoritesSaga(action) {
  try {
    const userId = action.payload
    const response = yield call(fetchFavorites, userId)

    yield put({
      type: favoritesTypes.FAVORITES_READ_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: favoritesTypes.FAVORITES_READ_FAILURE,
      payload: error,
    })
  }
}

export function* createFavoriteSaga(action) {
  try {
    const response = yield call(addFavorite, action.payload)

    yield put({
      type: favoritesTypes.FAVORITES_CREATE_SUCCESS,
      payload: response,
    })
    yield put(readFavorites(action.payload.userId))
  } catch (error) {
    yield put({
      type: favoritesTypes.FAVORITES_CREATE_FAILURE,
      payload: error,
    })
  }
}

export function* deleteFavoriteSaga(action) {
  try {
    yield call(removeFavorite, action.payload)
    yield put({
      type: favoritesTypes.FAVORITES_DELETE_SUCCESS,
      payload: action.payload,
    })
    yield put(readFavorites(action.payload.userId))
  } catch (error) {
    yield put({
      type: favoritesTypes.FAVORITES_DELETE_FAILURE,
      payload: error,
    })
  }
}

export default function* favoritesSaga() {
  yield takeEvery(favoritesTypes.FAVORITES_READ_REQUEST, readFavoritesSaga)
  yield takeEvery(favoritesTypes.FAVORITES_CREATE_REQUEST, createFavoriteSaga)
  yield takeEvery(favoritesTypes.FAVORITES_DELETE_REQUEST, deleteFavoriteSaga)
}
