import { object, string, ref } from 'yup'

const password = string()
  .matches(/\d+/, 'Password must contain at least one digit')
  .matches(/[A-Za-z]/, 'Password must contain at least one letter')
  .min(8, 'Password must be at least 8 characters')

export const SignInSchema = object({
  email: string().email().required(),
  password: password.required(),
})

export const signUpSchema = object({
  displayName: string().required().label('full name'),
  email: string().email().required(),
  password: password.required(),
  confirmPassword: password
    .required()
    .oneOf([ref('password'), null], 'Passwords must match')
    .label('confirm password'),
})

export const contactUsSchema = object({
  firstName: string().required().label('first name'),
  lastName: string().required().label('last name'),
  email: string().email().required(),
  reason: string().required(),
  message: string().required(),
})

export const RecoverySchema = object({
  email: string().email().required(),
})

const displayName = object({
  displayName: string().required().label('full name'),
})
const photoURL = object({
  photoURL: string().url().label('profile picture'),
})
const email = object({
  email: string().email().required(),
})
const currentPassword = object({
  currentPassword: password.required(),
  newPassword: password.required(),
  confirmPassword: password
    .required()
    .oneOf([ref('newPassword'), null], 'Passwords must match')
    .label('confirm password'),
})

export const settingsSchema = { displayName, photoURL, email, currentPassword }
