import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { firebaseConfig } from './config'

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const firestore = firebase.firestore()

export const GoogleProvider = new firebase.auth.GoogleAuthProvider()
GoogleProvider.setCustomParameters({ prompt: 'select_account' })

export const isUserVerified = async (userAuth) => {
  if (!userAuth) return false

  try {
    const user = auth.currentUser
    return user.emailVerified
  } catch (err) {
    console.error('Error verifying user:', err)
    return false
  }
}

export const sendEmailVerification = async (userAuth) => {
  if (!userAuth) return

  try {
    const user = auth.currentUser
    await user.sendEmailVerification()
  } catch (err) {
    console.error('Error sending email verification:', err)
  }
}

// Function to update user profile in Firestore
export const updateUserProfile = async ({ userAuth, additionalData = {} }) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)

  try {
    await userRef.update(additionalData)
  } catch (err) {
    console.error('Error updating user profile:', err)
    throw err
  }

  return userRef
}

// Function to update user email
export const updateUserEmail = async (userAuth, newEmail) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)

  try {
    await userAuth.updateEmail(newEmail)
    await userRef.update({ email: newEmail })
  } catch (err) {
    console.error('Error updating user email:', err)
    throw err
  }

  return userRef
}

// Function to reauthenticate user
export const reauthenticateUser = async (userAuth, currentPassword) => {
  if (!userAuth) return

  const credential = firebase.auth.EmailAuthProvider.credential(
    userAuth.email,
    currentPassword
  )

  try {
    await userAuth.reauthenticateWithCredential(credential)
  } catch (err) {
    console.error('Error reauthenticating user:', err)
    throw err
  }
}

// Function to update user password
export const updateUserPassword = async (userAuth, newPassword) => {
  if (!userAuth) return

  try {
    await userAuth.updatePassword(newPassword)
  } catch (err) {
    console.error('Error updating user password:', err)
    throw err
  }
}

export const handleUserProfile = async ({ userAuth, additionalData }) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    const { displayName, email, photoURL } = userAuth
    const createdDate = new Date()
    const userRoles = ['user']

    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        createdDate,
        userRoles,
        ...additionalData,
      })
    } catch (err) {
      console.error('Error creating user profile:', err)
    }
  }

  return userRef
}

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe()
      resolve(userAuth)
    }, reject)
  })
}
