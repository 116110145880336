import { useRef } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { FaCheck } from 'react-icons/fa6'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import { MdErrorOutline } from 'react-icons/md'

const Modal = ({ openModal, setOpenModal, navigate }) => {
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (!ref.current.contains(event.target)) {
      setOpenModal(null)
    }
  }

  return (
    <div
      className={`fixed z-20 inset-0 overflow-y-auto backdrop-brightness-75 flex items-center justify-center ${openModal ? 'block' : 'hidden'}`}
      onClick={handleClickOutside}
    >
      <div
        className={`relative max-h-fit w-[370px] sm:w-[400px] text-gray-800 bg-white shadow-xl rounded-md overflow-hidden`}
        ref={ref}
      >
        <RxCross2
          onClick={() => setOpenModal(null)}
          className='absolute right-[5px] top-1 size-6 cursor-pointer opacity-80 hover:opacity-100'
        />
        <div className='font-semibold text-lg px-3 py-5 flex flex-col gap-2 items-center'>
          {openModal === 'success' ? (
            <>
              <FaCheck className='text-3xl text-green-600' />
              <h1 className=''>Thank you for contacting us.</h1>
              <p className=''>We will get back to you as soon as possible.</p>
            </>
          ) : (
            <>
              <MdErrorOutline className='text-3xl text-red-500' />
              <h1 className=''>Something went wrong!</h1>
              <p>We are currently unable to deliver your message.</p>
              <p>Please try again later.</p>
            </>
          )}

          <button
            type='button'
            onClick={() => navigate('/')}
            className='flex items-center justify-center mx-auto gap-2 text-sm font-semibold border border-blue-100 hover:border-blue-300 hover:bg-gray-50 w-60 h-9 rounded mt-5 text-gray-700 transition-all duration-300'
          >
            <MdOutlineKeyboardBackspace className='text-lg' />
            Return To Homepage
          </button>
        </div>
      </div>
    </div>
  )
}

export default Modal
