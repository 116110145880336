import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkUserSession } from './redux/User/user.actions'
import Router from './Router'
import './App.css'

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
})

const App = () => {
  const { currentUser } = useSelector(mapState)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkUserSession())
  }, [dispatch])

  return <Router currentUser={currentUser} />
}

export default App
