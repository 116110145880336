import React from 'react'
import './styles.scss'
import Container from 'react-bootstrap/Container'
import TermsDetails from '../../components/TermsDetails'

const TermsConditions = () => {
  return (
    <Container className='center-text'>
      <Container>
        <TermsDetails text='Terms & Conditions' />
      </Container>
    </Container>
  )
}

export default TermsConditions
