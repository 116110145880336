import React from 'react'
import HeroSection from '../../components/HeroSection'
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data'
import SliderSection from '../../components/Slider'

const Home = () => {
  return (
    <>
      <HeroSection {...homeObjOne} />
      <SliderSection />
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjTwo} />
      <HeroSection {...homeObjFour} />
    </>
  )
}

export default Home
