import React from 'react'
import { FaRegCircleUser } from 'react-icons/fa6'
import { IoSettingsOutline } from 'react-icons/io5'
import { FiLogOut } from 'react-icons/fi'
import { signOutUserStart } from '../../redux/User/user.actions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const UserMenu = ({ openUserMenu, setOpenUserMenu }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const menuItems = [
    { title: 'Profile', path: '/profile', icon: <FaRegCircleUser /> },
    { title: 'Settings', path: '/settings', icon: <IoSettingsOutline /> },
    { title: 'Log Out', path: '/', icon: <FiLogOut /> },
  ]

  const handleClictMenuItem = (selectedMenuItem) => {
    switch (selectedMenuItem) {
      case 'Log Out':
        dispatch(signOutUserStart())
        break
      case 'Profile':
        navigate('/account/profile')
        break
      case 'Settings':
        navigate('/account/settings')
        break

      default:
        break
    }
    setOpenUserMenu(false)
  }

  return (
    <div
      className={`absolute top-[50px] right-0 flex flex-col justify-center gap-1 overflow-hidden w-28 bg-gray-100 text-gray-800 rounded-md shadow-md z-50 transition-height duration-300
    ${openUserMenu ? 'h-28' : 'h-0 '}
    `}
    >
      {menuItems.map((item) => (
        <div
          key={item.title}
          className='flex items-center gap-2 py-1 px-2 cursor-pointer hover:bg-gray-200'
          onClick={() => handleClictMenuItem(item?.title)}
        >
          <span className='text-lg'>{item.icon} </span>
          <span className='text-sm'>{item.title}</span>
        </div>
      ))}
    </div>
  )
}

export default UserMenu
