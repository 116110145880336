import { auth } from './../../firebase/utils'

export const handleResetPasswordAPI = (email) => {
  const config = {
    url: 'http://www.arbithub.com/login',
  }

  return new Promise((resolve, reject) => {
    auth
      .sendPasswordResetEmail(email, config)
      .then(() => {
        resolve()
      })
      .catch(() => {
        const err = ['Email not found please try again.']
        reject(err)
      })
  })
}

// Error message handler
export const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'auth/email-already-in-use':
      return 'The email address is already in use.'
    case 'auth/too-many-requests':
      return 'Too many requests. Please try again later.'
    case 'auth/network-request-failed':
      return 'A network error occurred. Please try again.'
    case 'auth/user-not-found':
      return 'User not found. Please check your email.'
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.'
    case 'auth/requires-recent-login':
      return 'Sensitive action, please log in again to continue.'
    default:
      return 'An unexpected error occurred. Please try again.'
  }
}
