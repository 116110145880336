// Router.js
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

// Components
import VerificationToolbar from './components/VerificationToolbar'

// HOC
import WithAuth from './hoc/WithAuth'
import WithSubscription from './hoc/WithSubscription'

// Layouts
import HomePageLayout from './layouts/HomePageLayout'
import EmptyLayout from './layouts/EmptyLayout'

// Pages
import Admin from './pages/Admin'
import Cancel from './pages/Cancel'
import Success from './pages/Success'
import Home from './pages/Homepage'
import Login from './pages/Login'
import Registration from './pages/Registration'
import Recovery from './pages/Recovery'
import Pricing from './pages/Pricing'
import OnlineArbitrageProducts from './pages/OnlineArbitrageProducts'
import WholesaleProducts from './pages/WholesaleProducts'
import Favorites from './pages/Favorites'
import Contact from './pages/Contact'
import TermsConditions from './pages/TermsConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Profile from './pages/Account/Profile'
import Settings from './pages/Account/Settings'
import ScrollToTop from './hoc/ScrollToTop'

const Router = ({ currentUser }) => {
  return (
    <>
      <VerificationToolbar />
      <ScrollToTop>
        <Routes>
          <Route
            path='/'
            element={
              <HomePageLayout>
                <Home />
              </HomePageLayout>
            }
          />
          <Route
            path='/admin'
            element={
              <WithAuth admin_only={true}>
                <EmptyLayout>
                  <Admin />
                </EmptyLayout>
              </WithAuth>
            }
          />
          <Route
            path='/home'
            element={
              <HomePageLayout>
                <Home />
              </HomePageLayout>
            }
          />
          <Route
            path='/terms-and-conditions'
            element={
              <HomePageLayout>
                <TermsConditions />
              </HomePageLayout>
            }
          />
          <Route
            path='/privacy-policy'
            element={
              <HomePageLayout>
                <PrivacyPolicy />
              </HomePageLayout>
            }
          />
          <Route
            path='/sign-up'
            element={
              currentUser &&
              currentUser.subscription &&
              currentUser.subscription.planId ? (
                <Navigate to='/' />
              ) : (
                <EmptyLayout>
                  <Registration />
                </EmptyLayout>
              )
            }
          />
          <Route
            path='/login'
            element={
              currentUser ? (
                <Navigate to='/' />
              ) : (
                <EmptyLayout>
                  <Login />
                </EmptyLayout>
              )
            }
          />
          <Route
            path='/recovery'
            element={
              currentUser ? (
                <Navigate to='/' />
              ) : (
                <EmptyLayout>
                  <Recovery />
                </EmptyLayout>
              )
            }
          />
          <Route
            path='/success'
            element={
              <EmptyLayout>
                <Success />
              </EmptyLayout>
            }
          />
          <Route
            path='/cancel'
            element={
              <EmptyLayout>
                <Cancel />
              </EmptyLayout>
            }
          />
          <Route
            path='/products'
            element={
              <HomePageLayout>
                <WithSubscription>
                  <OnlineArbitrageProducts />
                </WithSubscription>
              </HomePageLayout>
            }
          />
          <Route
            path='/wholesale'
            element={
              <HomePageLayout>
                <WithSubscription>
                  <WholesaleProducts />
                </WithSubscription>
              </HomePageLayout>
            }
          />
          <Route
            path='/pricing'
            element={
              <HomePageLayout>
                <Pricing />
              </HomePageLayout>
            }
          />
          <Route
            path='/contact-us'
            element={
              <HomePageLayout>
                <Contact />
              </HomePageLayout>
            }
          />
          <Route
            path='/favorites'
            element={
              <WithAuth>
                <HomePageLayout>
                  <Favorites />
                </HomePageLayout>
              </WithAuth>
            }
          />
          <Route
            path='/account/profile'
            element={
              <WithAuth>
                <HomePageLayout>
                  <Profile />
                </HomePageLayout>
              </WithAuth>
            }
          />
          <Route
            path='/account/settings'
            element={
              <WithAuth>
                <HomePageLayout>
                  <Settings />
                </HomePageLayout>
              </WithAuth>
            }
          />
        </Routes>
      </ScrollToTop>
    </>
  )
}

export default Router
