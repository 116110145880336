import React, { useEffect, useState } from 'react'
import { IoIosArrowUp } from 'react-icons/io'
import { IoIosArrowDown } from 'react-icons/io'

const FilterAccordion = ({
  item,
  filterOptions,
  setFilterOptions,
  filterTempOps,
  setFilterTempOps,
  mobileFilterPanel,
  setCurrentPage,
}) => {
  const [expanded, setExpanded] = useState(false)
  const contentHeight = `${item.content.length * 35}px`

  useEffect(() => {
    mobileFilterPanel && setExpanded(true)
    item.header === 'Categories' && setExpanded(true)
  }, [mobileFilterPanel])

  const handleFilterCategories = (content) => {
    if (mobileFilterPanel) {
      setFilterTempOps({ ...filterTempOps, category: content })
    } else {
      setFilterOptions({ ...filterOptions, category: content })
      setFilterTempOps({ ...filterTempOps, category: content })
      setCurrentPage(1)
    }
  }

  const handleFilterOptions = (field) => {
    if (mobileFilterPanel) {
      setFilterTempOps({ ...filterTempOps, [field]: !filterTempOps[field] })
    } else {
      setFilterOptions({ ...filterOptions, [field]: !filterOptions[field] })
      setFilterTempOps({ ...filterTempOps, [field]: !filterTempOps[field] })
      setCurrentPage(1)
    }
  }

  return (
    <div className={`w-full border-gray-200 shadow-sm`}>
      <div className='flex justify-between items-center h-12 font-semibold px-2'>
        {item.header.toUpperCase()}
        <div className='cursor-pointer'>
          {expanded ? (
            <IoIosArrowUp onClick={() => setExpanded(!expanded)} />
          ) : (
            <IoIosArrowDown onClick={() => setExpanded(!expanded)} />
          )}
        </div>
      </div>
      <div
        className='accordion-container'
        style={{
          overflowY: item.header === 'Categories' ? 'auto' : 'hidden',
          height: expanded ? contentHeight : 0,
          maxHeight: 294,
          transition: 'height 0.3s ease',
        }}
      >
        {item.content.map((content, index) =>
          item.header === 'Categories' ? (
            <p
              key={index}
              onClick={() => handleFilterCategories(content)}
              className={`pl-3 text-[15px] leading-6 cursor-pointer hover:font-medium
              ${!mobileFilterPanel && content === filterOptions.category && 'text-red-600'}
              ${mobileFilterPanel && content === filterTempOps.category && 'text-red-600'}`}
            >
              {content}
            </p>
          ) : (
            <div
              key={index}
              onClick={() => handleFilterOptions(content?.field)}
              className='flex items-center'
            >
              <div className='w-8 h-8 flex justify-center items-center rounded-full active:animate-ping hover:bg-[#e8e8e8] transition-colors duration-200'>
                <input
                  type='checkbox'
                  id={content?.field}
                  className='h-4 w-4 rounded focus:ring-blue-500 focus:border-blue-500'
                  checked={
                    mobileFilterPanel
                      ? filterTempOps[content?.field] || false
                      : filterOptions[content?.field] || false
                  }
                  onChange={() => handleFilterOptions(content?.field)}
                />
              </div>
              <label htmlFor={content} className='text-[15px] mt-0.5'>
                {content?.title}
              </label>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default FilterAccordion
