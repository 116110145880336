/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import SliderCard from './SliderCard'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './styles.css'
import Skeleton from './Skeleton'
import { modifyProductData } from '../Shared/modifyProductData'

export default function SliderSection() {
  const [slidesPerView, setSlidesPerView] = useState(
    Math.floor(window.innerWidth / 360)
  )
  const [sliderData, setSliderData] = useState([])

  useEffect(() => {
    const fetchSliderData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/slider-products`
      )
      const data = await response.json()
      const modifiedData = modifyProductData(data)
      setSliderData(modifiedData)
    }

    fetchSliderData()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(Math.floor(window.innerWidth / 360))
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <React.Fragment>
      {sliderData.length ? (
        <Swiper
          className='h-[460px] bg-[#eff2f2] !pt-6 !pb-4 !px-9'
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
            pauseOnMouseEnter: true,
          }}
          slidesPerView={slidesPerView}
          loop={true}
        >
          <div>
            {sliderData.map((item, index) => (
              <SwiperSlide key={index}>
                <div className='flex justify-center'>
                  <SliderCard item={item} />
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      ) : (
        <div className='h-[460px] bg-[#eff2f2] flex flex-row justify-center items-center px-9 gap-[60px]'>
          {[...Array(slidesPerView)].map((_, index) => (
            <div key={index}>
              <Skeleton />
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}
