import React, { useState } from 'react'
import { BiExpand, BiCollapse } from 'react-icons/bi'
import { columnsData, toolbarMenuItems } from './data'
import ToolbarMenu from './ToolbarMenu'
import TableRows from './TableRows'
import TablePagination from './TablePagination'
import { useSelector } from 'react-redux'
import FadeLoader from 'react-spinners/FadeLoader'
import './styles.css'

const TableView = ({
  data,
  page,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
}) => {
  const [expandAll, setExpandAll] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [columns, setColumns] = useState(columnsData)
  const [density, setDensity] = useState(60)
  const { loading } = useSelector((state) => state.productsData)
  const user = useSelector((state) => state.user.currentUser)
  const isUserAdmin = user?.userRoles.includes('admin')

  const handleClickToolbar = (menuTitle, menuItems) => {
    setSelectedMenu(
      selectedMenu?.menuTitle === menuTitle ? null : { menuTitle, menuItems }
    )
  }

  return (
    <div className='w-[98%] lg:w-[95%] mx-auto font-sans text-gray-900 mb-5'>
      <div className='text-[15px] border border-solid border-gray-200 rounded-t-md h-[calc(100vh-190px)] overflow-auto'>
        <table className='border-collapse w-full'>
          <thead className='sticky top-0 z-20 bg-blue-950 text-gray-50'>
            <tr className='border-t-0'>
              <th colSpan='99' className='h-11 font-semibold'>
                <div className='flex gap-7 px-2 w-fit sticky top-0 left-1'>
                  {toolbarMenuItems.map(({ menuTitle, menuItems, icon }) => (
                    <div
                      key={menuTitle}
                      className='flex gap-1 items-center relative cursor-pointer hover:text-gray-50'
                      onClick={() => handleClickToolbar(menuTitle, menuItems)}
                      id={menuTitle}
                    >
                      {icon}
                      {menuTitle}
                    </div>
                  ))}
                  {selectedMenu && (
                    <ToolbarMenu
                      {...{
                        columns,
                        setColumns,
                        selectedMenu,
                        setSelectedMenu,
                        density,
                        setDensity,
                        data,
                      }}
                    />
                  )}
                </div>
              </th>
            </tr>
            <tr className='border-t-0'>
              <th className='min-w-12 max-w-12 h-11 flex items-center justify-center'>
                <div
                  onClick={() => setExpandAll(!expandAll)}
                  className='flex justify-center items-center text-2xl w-9 h-9 rounded-full cursor-pointer transition-colors duration-200 hover:bg-gray-900'
                >
                  {columns.some((col) => col.isVisible) && (
                    <>{expandAll ? <BiCollapse /> : <BiExpand />}</>
                  )}
                </div>
              </th>
              {columns.map((col) =>
                !col.isVisible ? null : (
                  <th
                    key={col.th}
                    className='font-semibold'
                    style={{
                      minWidth: col.minWidth,
                      maxWidth: col.maxWidth || undefined,
                      textAlign: col.align,
                    }}
                  >
                    <div className='peer h-full px-1.5 line-clamp-1'>
                      {col.header}
                    </div>
                    <div className='peer-hover:visible invisible absolute px-2 py-1 text-sm border border-solid border-gray-300 rounded-md shadow-md text-black bg-white z-20 transition-visible peer-hover:delay-700 font-normal'>
                      {col.header}
                    </div>
                  </th>
                )
              )}
              <th
                className={`${isUserAdmin ? 'min-w-28 max-w-28' : 'min-w-20 max-w-20'} text-left font-semibold px-2 text-red-600`}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <div className='fixed inset-0 z-50 flex justify-center items-center '>
                <FadeLoader color='#2E7D32' />
              </div>
            )}
            {data.map((item) => (
              <TableRows
                key={item.id}
                {...{
                  item,
                  expandAll,
                  columns,
                  density,
                  page,
                  user,
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className='bg-blue-950 text-gray-50 h-11 flex items-center text-left px-2 rounded-b-md'>
        {page === 'products' ? (
          <div className='flex justify-end items-center w-full'>
            <TablePagination
              {...{ data, pageSize, setPageSize, currentPage, setCurrentPage }}
            />
          </div>
        ) : (
          <div className='flex justify-left items-center w-full'>
            Total Rows: {data?.length}
          </div>
        )}
      </div>
    </div>
  )
}

export default TableView
