import { FiDownload } from 'react-icons/fi'
import { TfiLayoutColumn3 } from 'react-icons/tfi'
import { MdExpand } from 'react-icons/md'
import {
  faAmazon,
  faGoogle,
  faFontAwesome,
} from '@fortawesome/free-brands-svg-icons'

export const searchButtons = [
  { tooltipText: 'List Amazon product', icon: faAmazon, URL: 'amazonURL' },
  {
    tooltipText: 'Search for product title on Google',
    icon: faGoogle,
    URL: 'googleSearchUrl',
  },
  { tooltipText: 'View chart on Keepa', icon: faFontAwesome, URL: 'keepaURL' },
]

export const columnsData = [
  {
    isVisible: true,
    header: 'Image',
    field: 'modProductThumbnail',
    minWidth: 100,
    maxWidth: 110,
    align: 'center',
  },
  {
    isVisible: true,
    header: 'Product Name',
    field: 'name',
    minWidth: 220,
    maxWidth: 400,
    align: 'left',
  },
  {
    isVisible: true,
    header: 'Category',
    field: 'category',
    minWidth: 150,
    align: 'left',
  },
  {
    isVisible: true,
    header: 'Marketplace To Buy',
    field: 'modBuyMarket',
    minWidth: 155,
    align: 'left',
  },
  {
    isVisible: true,
    header: 'Buy Price',
    field: 'modBuyPrice',
    minWidth: 85,
    align: 'left',
  },
  {
    isVisible: true,
    header: 'Marketplace To Sell',
    field: 'sellMarket',
    minWidth: 155,
    align: 'left',
  },
  {
    isVisible: true,
    header: 'Sell Price',
    field: 'modSellPrice',
    minWidth: 85,
    align: 'left',
  },
  {
    isVisible: true,
    header: 'ROI',
    field: 'modROI',
    minWidth: 85,
    align: 'left',
  },
  {
    isVisible: true,
    header: 'Profit',
    field: 'modProfit',
    minWidth: 85,
    tooltip: 'modProfitTooltip',
    align: 'left',
  },
  {
    isVisible: true,
    header: 'Best Seller Rank',
    field: 'modSellRank',
    minWidth: 105,
    align: 'left',
  },
  {
    isVisible: false,
    header: 'Unit Sell Per Month',
    field: 'modUnitSales',
    minWidth: 100,
    align: 'left',
  },
  {
    isVisible: false,
    header: 'Total Buy Cost',
    field: 'modTotalBuyCost',
    minWidth: 100,
    align: 'left',
    tooltip: 'modTotalCostTooltip',
  },
  {
    isVisible: false,
    header: 'Total Amazon Fees',
    field: 'modFbaFee',
    minWidth: 100,
    align: 'left',
    tooltip: 'modFbaFeeTooltip',
  },
  {
    isVisible: false,
    header: 'List Price',
    field: 'modListPrice',
    minWidth: 100,
    align: 'left',
  },
  {
    isVisible: false,
    header: 'Profit Margin',
    field: 'modProfitMargin',
    minWidth: 100,
    align: 'left',
    tooltip: 'modProfitMarginTooltip',
  },
  {
    isVisible: false,
    header: 'Number Of Sellers',
    field: 'numOfSellers',
    minWidth: 100,
    align: 'left',
  },
  {
    isVisible: false,
    header: 'Estimated Tax',
    field: 'modEstimatedTax',
    minWidth: 100,
    align: 'left',
  },
]

export const toolbarMenuItems = [
  {
    menuTitle: 'COLUMNS',
    menuItems: columnsData.map((col) => col.header),
    icon: <TfiLayoutColumn3 />,
  },
  {
    menuTitle: 'DENSITY',
    menuItems: ['Compact', 'Regular', 'Comfortable'],
    icon: <MdExpand />,
  },
  {
    menuTitle: 'EXPORT',
    menuItems: ['CSV Export', 'Excel Export'],
    icon: <FiDownload />,
  },
]

export const CardViewTableData = [
  {
    header: 'Total Buy Cost',
    field: 'modTotalBuyCost',
    tooltipText: 'modTotalCostTooltip',
  },
  {
    header: 'Total Amazon Fees',
    field: 'modFbaFee',
    tooltipText: 'modFbaFeeTooltip',
  },
  { header: 'Profit', field: 'modProfit', tooltipText: 'modProfitTooltip' },
  {
    header: 'Profit Margin',
    field: 'modProfitMargin',
    tooltipText: 'modProfitMarginTooltip',
  },
  { header: 'List Price', field: 'modListPrice' },
]
