export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Online Arbitrage',
  headline: 'Free sign up to expand your Amazon business',
  description:
    'Get access to all search results of major online retail stores after just one click.',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: 'images/png1.png',
  alt: 'Credit Card',
}

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '100% BEGINNER FRIENDLY',
  headline: 'Wherever you are in your seller journey, we can help',
  description:
    'We have you covered no matter where you are. Perfect tool for beginners to get started.',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: 'images/png4.png',
  alt: 'Vault',
}

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'INSTANT ACCESS',
  headline: 'Super fast and simple user interface',
  description:
    'Get everything set up and ready after one click. No more waiting for search results. Access all search results instantly using the simplest UI.',
  buttonLabel: 'Start Now',
  imgStart: true,
  img: 'images/png3.png',
  alt: 'Vault',
}

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Filter search results and use deep data analysis',
  description:
    'Advanced algorithms determine the products most suited for you to track, purchase from online retailers, and resell on Amazon.',
  buttonLabel: 'Sign Up Now',
  imgStart: true,
  img: 'images/png2.png',
  alt: 'Vault',
}
