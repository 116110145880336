import React, { useEffect } from 'react'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import { GoDotFill } from 'react-icons/go'
import { exportToCSV, exportToExcel } from './Exports'
import { createPortal } from 'react-dom'

const ToolbarMenu = ({
  columns,
  setColumns,
  selectedMenu,
  setSelectedMenu,
  density,
  setDensity,
  data,
}) => {
  const renderPosition = document.getElementById(selectedMenu.menuTitle)
  const selectedDensity =
    density === 80 ? 'Comfortable' : density === 60 ? 'Regular' : 'Compact'

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (renderPosition && !renderPosition.contains(event.target)) {
        setSelectedMenu(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedMenu, renderPosition, setSelectedMenu])

  const handleClictMenuItem = (item) => {
    switch (item) {
      case 'Compact':
        setDensity(40)
        break
      case 'Regular':
        setDensity(60)
        break
      case 'Comfortable':
        setDensity(80)
        break
      case 'CSV Export':
        exportToCSV(data)
        break
      case 'Excel Export':
        exportToExcel(data)
        break
      default:
        if (selectedMenu.menuTitle === 'COLUMNS') {
          setColumns(
            columns.map((col) =>
              col.header === item ? { ...col, isVisible: !col.isVisible } : col
            )
          )
        }
        break
    }
    selectedMenu.menuTitle !== 'COLUMNS' && setSelectedMenu(null)
  }

  return (
    <>
      {selectedMenu &&
        renderPosition &&
        createPortal(
          <div className='bg-gray-100 text-black overflow-hidden py-1 rounded-sm shadow-md z-50 absolute top-7'>
            <div className='max-h-60 overflow-y-auto overflow-x-hidden font-normal'>
              {selectedMenu.menuItems.map((item) => (
                <div
                  key={item}
                  className='flex items-center gap-4 py-1 ps-2 pe-3 min-w-max cursor-pointer hover:bg-gray-200'
                  onClick={() => handleClictMenuItem(item)}
                >
                  {selectedMenu.menuTitle !== 'EXPORT' && (
                    <span className='w-1'>
                      {selectedDensity === item && <GoDotFill />}
                      {selectedMenu.menuTitle === 'COLUMNS' &&
                        columns.find((col) => col.header === item)
                          ?.isVisible && (
                          <ImCheckboxChecked className='text-blue-500' />
                        )}
                      {selectedMenu.menuTitle === 'COLUMNS' &&
                        !columns.find((col) => col.header === item)
                          ?.isVisible && (
                          <ImCheckboxUnchecked className='text-gray-500' />
                        )}
                    </span>
                  )}
                  {item}
                </div>
              ))}
            </div>
          </div>,
          renderPosition
        )}
    </>
  )
}

export default ToolbarMenu
