import { useEffect, Fragment } from 'react'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()
function ScrollToTop({ children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [])

  return <Fragment>{children}</Fragment>
}

export default ScrollToTop
