import React from 'react'
import logo from '../../assets/cover.png'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { socialMediaLinks } from './data'

function Footer() {
  const { currentUser } = useSelector((state) => state.user)
  const footerMenuItems = [
    { title: 'Contact Us', link: '/contact-us' },
    { title: 'Terms & Conditions', link: '/terms-and-conditions' },
    { title: 'Privacy Policy', link: '/privacy-policy' },
    { title: 'Pricing', link: '/pricing' },
  ]
  if (!currentUser) {
    footerMenuItems.push({ title: 'Login', link: '/login' })
  }

  const currentYear = new Date().getFullYear()

  return (
    <footer className='flex flex-col lg:flex-row bg-[#ECECEC] text-gray-800 py-5 items-center'>
      <aside className='w-full lg:w-[calc((100vw-520px)/2)]'>
        <div className='group flex justify-center -ml-10 md:ml-5 mb-5 cursor-pointer'>
          <img src={logo} alt='Arbithub Logo' className='h-14 w-16' />
          <div className='relative text-center group-hover:text-black'>
            <p className='text-4xl'>arbithub</p>
            <hr className='border-0 border-t border-[#90384ea9] mt-[5px] mx-1' />

            <p className='absolute -bottom-[2px] left-7 px-1 bg-[#ECECEC] text-[#C05666] group-hover:text-[#ba3a4e] text-[10px] font-semibold'>
              Online Arbitrage
            </p>
          </div>
        </div>
      </aside>
      <main className='w-[520px]'>
        <section className=''>
          <ul className='flex flex-col md:flex-row items-center md:justify-between font-semibold'>
            {footerMenuItems.map((item) => (
              <li
                key={item.title}
                className='text-gray-800 hover:text-black transition-colors'
              >
                <Link to={item.link}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </section>
        <hr className='border-0 border-t border-gray-300 my-5' />
        <section className='flex justify-center gap-8 text-3xl'>
          {socialMediaLinks.map((item) => (
            <a
              style={{ color: item.color }}
              className='hover:scale-110 transition-scale duration-300'
              key={item.label}
              href={item.link}
              target='_blank'
              rel='noopener noreferrer'
              aria-label={item.label}
            >
              {item.icon}
            </a>
          ))}
        </section>
        <section className='text-center mt-6 text-xs'>
          {`© ${currentYear} Arbithub. All Rights Reserved.`}
        </section>
      </main>
    </footer>
  )
}

export default Footer
