// eslint-disable-next-line import/named
import { takeLatest, call, all, put } from 'redux-saga/effects'
import {
  auth,
  handleUserProfile,
  getCurrentUser,
  GoogleProvider,
  updateUserProfile,
  updateUserEmail,
  reauthenticateUser,
  updateUserPassword,
} from './../../firebase/utils'
import userTypes from './user.types'
import {
  signInSuccess,
  signOutUserSuccess,
  resetPasswordSuccess,
  updateUserProfileSuccess,
  userError,
} from './user.actions'
import { handleResetPasswordAPI, getErrorMessage } from './user.helpers'

export function* getSnapshotFromUserAuth(
  user,
  additionalData = {},
  signInMethod
) {
  try {
    const userRef = yield call(handleUserProfile, {
      userAuth: user,
      additionalData,
    })
    const snapshot = yield userRef.get()
    yield put(
      signInSuccess({
        id: snapshot.id,
        ...snapshot.data(),
        emailVerified: user.emailVerified,
        signInMethod:
          signInMethod || user.providerData[0]?.providerId || 'unknown',
      })
    )
  } catch (err) {
    console.log(err)
  }
}

export function* signUpUser({ payload: { displayName, email, password } }) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password)
    const additionalData = { displayName }
    yield getSnapshotFromUserAuth(user, additionalData)
  } catch (err) {
    const errorMessage = getErrorMessage(err.code)
    yield put(userError(errorMessage))
  }
}

export function* onSignUpUserStart() {
  yield takeLatest(userTypes.SIGN_UP_USER_START, signUpUser)
}

export function* emailSignIn({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password)
    yield getSnapshotFromUserAuth(user, {}, 'password')
  } catch (err) {
    const errorMessage = getErrorMessage(err.code)
    yield put(userError(errorMessage))
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(userTypes.EMAIL_SIGN_IN_START, emailSignIn)
}

export function* googleSignIn() {
  try {
    const { user } = yield auth.signInWithPopup(GoogleProvider)
    yield getSnapshotFromUserAuth(user, {}, 'google.com')
  } catch (err) {
    const errorMessage = getErrorMessage(err.code)
    yield put(userError(errorMessage))
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn)
}

export function* signOutUser() {
  try {
    yield auth.signOut()
    yield put(signOutUserSuccess())
  } catch (err) {
    console.log(err)
  }
}

export function* onSignOutUserStart() {
  yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser)
}

export function* resetPassword({ payload: { email } }) {
  try {
    yield call(handleResetPasswordAPI, email)
    yield put(resetPasswordSuccess())
  } catch (err) {
    yield put(userError(err))
  }
}

export function* onResetPasswordStart() {
  yield takeLatest(userTypes.RESET_PASSWORD_START, resetPassword)
}

export function* onHandleSubscriptionUpdateStart() {
  yield takeLatest(
    userTypes.HANDLE_SUBSCRIPTION_UPDATE_START,
    handleSubscriptionUpdate
  )
}

export function* handleSubscriptionUpdate({
  payload: {
    sessionId,
    planId,
    planStartDate,
    planEndDate,
    planDuration,
    subscriptionId,
    status,
  },
}) {
  try {
    const userAuth = yield getCurrentUser()
    if (!userAuth) return

    const additionalData = {
      subscription: {
        sessionId,
        planId,
        planStartDate,
        planEndDate,
        planDuration,
        subscriptionId,
        status,
      },
    }

    const userRef = yield call(updateUserProfile, {
      userAuth,
      additionalData,
    })

    const snapshot = yield userRef.get()
    yield put(
      signInSuccess({
        id: snapshot.id,
        ...snapshot.data(),
      })
    )
  } catch (err) {
    console.log(err)
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser()
    if (!userAuth) return

    const method = userAuth.providerData[0]?.providerId || 'unknown'
    yield getSnapshotFromUserAuth(userAuth, {}, method)
  } catch (err) {
    console.log(err)
  }
}

export function* onCheckUserSession() {
  yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated)
}

export function* updateUserProfileSaga({
  payload: { displayName, email, photoURL, currentPassword, newPassword },
}) {
  try {
    const userAuth = yield getCurrentUser()
    if (!userAuth) return

    if (displayName) {
      yield call(updateUserProfile, {
        userAuth,
        additionalData: { displayName },
      })
    }

    if (email) {
      yield call(updateUserEmail, userAuth, email)
    }

    if (photoURL) {
      yield userAuth.updateProfile({ photoURL })
      yield call(updateUserProfile, { userAuth, additionalData: { photoURL } })
    }

    if (currentPassword) {
      yield call(reauthenticateUser, userAuth, currentPassword)
    }

    if (newPassword) {
      yield call(updateUserPassword, userAuth, newPassword)
    }

    const userRef = yield call(updateUserProfile, { userAuth })
    const snapshot = yield userRef.get()
    yield put(updateUserProfileSuccess({ id: snapshot.id, ...snapshot.data() }))
  } catch (err) {
    const errorMessage = getErrorMessage(err.code || err.message)
    yield put(userError(errorMessage))
  }
}

export function* onUpdateUserProfileStart() {
  yield takeLatest(userTypes.UPDATE_USER_PROFILE_START, updateUserProfileSaga)
}

export default function* userSagas() {
  yield all([
    call(onHandleSubscriptionUpdateStart),
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutUserStart),
    call(onSignUpUserStart),
    call(onResetPasswordStart),
    call(onGoogleSignInStart),
    call(onUpdateUserProfileStart),
  ])
}
