import React from 'react'
import './Pricing.css'
import Paragraph from '../Paragraph'

function TermsDetails({ text }) {
  return (
    <div>
      <section className='container price-font'>
        <header className='pricing-header'>
          <Paragraph header={text} text='Agreement between user and Arbithub' />
        </header>
        <div
          style={{ width: '100%', maxWidth: '100%', maxHeight: '100%' }}
          className='cardContainer'
        >
          <div className='card'>
            <div className='cardTop'>
              <Paragraph
                header='Welcome to Arbithub'
                text='Arbithub product, platform and associated tools (collectively, the “Service”) are operated by Arbithub LLC. 
                Arbithub is offered to You conditioned on Your acceptance without modification of the terms, 
                conditions and notices contained herein (the “Terms”). Your use of Arbithub constitutes agreement to all such Terms by You and the entity 
                You represent (collectively, “You/Your”). If You do not agree to all the terms and conditions of this agreement, then You may not access the Service.
                Arbithub is a research tool intended for use by individuals and organizations who buy products for resale on Amazon marketplaces.
                '
              />
              <Paragraph
                header='Use of Service and Intellectual Property'
                text='You are granted a non-exclusive, non-transferable, revocable license to
        access and use Arbithub strictly in accordance with these terms of
        service, for Your personal, internal use during the term at the level of
        service for which You have paid all applicable fees. As a condition of
        Your use of the Service, You warrant to Arbithub that You will not use
        the Site for any purpose that is unlawful or prohibited by these Terms.
        Any material downloaded from or obtained through the use of the Service
        is done at Your own discretion and risk. You agree that You, solely, are
        responsible for any damage to Your computer system or other devices,
        and/or for any loss of data that may result from the download of any
        such material.'
              />
              <Paragraph
                header='Fair-Use Policy'
                text='You may not use the Service in any manner which could damage, disable, overburden, or impair the Service or interfere with any other party’s use of the Service. You may not resell the Service. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Service, including use and deployment of third-party autonomous software programs, including screen and database scraping, spiders, robots, crawlers and any other automated activity with the purpose of obtaining information from the Service. Use of such means will result in Arbithub disabling or limiting Your usage of the Service. Arbithub reserves the right to enforce quotas and usage limits at its sole discretion, with or without notice, which may result in Arbithub disabling or limiting Your usage of the Service.'
              />
              <Paragraph
                header='Disruption of Service'
                text='You agree that for the purpose of system maintenance, Arbithub may occasionally require to cease providing a service or feature for an indefinite period of time, without prior notice or notification. You agree that Your access to the Service and select features may be interrupted or suspended for reasons, including but not limited to, equipment malfunctions, periodic system updates, maintenance or other actions Arbithub may choose to take. You agree that You are not entitled to refunds, reimbursements or other compensation when disruptions of service occur. '
              />
              <Paragraph
                header='Your Account'
                text='You must be eighteen years of age to use the Service. Information provided about Yourself or the organization You represent is true, accurate, current and complete. The use of disposable email addresses for registration, communication, or any other purpose of the Service is strictly prohibited. Arbithub reserves the right to verify and validate the authenticity of email addresses provided by users. Failure to comply with this policy may result in termination of user accounts.
You are responsible for maintaining the confidentiality of Your account and password and for restricting access to the Service. Your account may not be created by a third party or automated service. You acknowledge that Arbithub is not responsible for third party access or misuse of Your account that results from theft or misappropriation of Your account. Any unauthorized use of Your account must be reported to Arbithub. 
Arbithub and its associates reserve the right to refuse or cancel service, terminate accounts or remove or edit content in our sole discretion. 
All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Arbithub or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto. 
You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Service. 
Your use of the Service does not entitle You to make any unauthorized use of any protected content, and in particular You will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for Your personal or organizational use, and will make no other use of the content without the express written permission of Arbithub and the copyright owner. You agree that You do not acquire any ownership rights in any protected content. We do not grant You any licenses, express or implied, to the intellectual property of Arbithub or our licensors except as expressly authorized by these Terms. 
Should the Service not meet Your needs or expectations, You are not entitled to compensation, refunds, credits or other forms of relief. 
'
              />
              <Paragraph
                header='Trial Subscription'
                text='“Trial Subscription” means the first thirty-day period during which the Service is made available to You, and which starts upon Your online registration. Trial Subscription is permitted solely for You to use to determine whether to purchase a full subscription to the Service. Arbithub reserves the right to terminate any Trial Subscription not used for purposes of determining whether to purchase a subscription. A maximum of one (1) Trial Subscription is allowed per Arbithub user. Arbithub reserves the right to cease the Trial Subscription or refuse use of Trial Subscription if You have had prior use of a Trial Subscription. Multiple uses of Trial Subscription will cause cancellation of all active accounts by that user.
Arbithub reserves the right at any time to terminate the Trial Subscription with or without notice. During the Trial Subscription You may use the Service in accordance with terms and conditions of these Terms.
Cancellation of the Trial Subscription must be communicated to Arbithub 24 hours prior to the end of the Trial Subscription by emailing help@Arbithub.com with the email address associated to the account. Service will become chargeable after the Trial Subscription if no cancellation notice is received 24 hours prior to the end of Trial Subscription.'
              />
              <Paragraph
                header='Fees and Payment for Purchased Service'
                text='You agree to monthly recurring billing, starting immediately after Your 30-day Trial Subscription. Billing occurs on the same date each month (monthly subscription) and same date each year (annual subscription).  Fees will be billed in advance of use. You agree that You may not be refunded or credited for partial months of service (monthly subscription) or partial years of service (annual subscription), or for periods in which Your account remains open but You do not use the Service. While subscribed to Arbithub You will provide Arbithub with a valid debit or credit card (“card”) and authorize Arbithub to deduct charges against the card. You acknowledge anyone using a card represents and warrants that they are authorized to use that card, and that any and all charges may be billed to that card and will not be rejected. If Arbithub is unable to process Your card order for any reason Arbithub reserves the right to suspend Your account until Your payment can be processed. You agree that we may re-attempt payment processing upon receiving updated billing details.
Coupon codes are only eligible for first time users. You may not apply coupon codes after sign-up. Coupon codes applied to accounts found to not be first time users will be removed. Arbithub reserves the right to remove a coupon code at any time and for any reason.
'
              />
              <Paragraph
                header='Subscription Change and Account Cancellation'
                text='You may change Your Service subscription type at any time by accessing account details in Settings or by contacting help@Arbithub.com. Upgrading to a higher priced subscription authorizes Arbithub to charge a prorated amount at the time the upgrade is conducted. Arbithub reserves the right to not offer credits if You change to a lower priced subscription. The invoice following any subscription change will reflect the new billing amount. 
You may cancel Your Service subscription at any time via email by contacting help@Arbithub.com from the email address associated with Your Arbithub account. Cancellation requests must occur 24 hours before Your next billing period to not incur an additional subscription charge.  Cancellations after billing are not entitled to refunds. 
'
              />
              <Paragraph
                header='Illicit Access'
                text='Arbithub reserves the right to terminate the account of any user found to have previously used illicit versions of the Service. This includes any versions not marketed or invoiced by Arbithub.'
              />
              <Paragraph
                header='Electronic Communications'
                text='Using Arbithub or sending emails to Arbithub constitutes electronic communications. You consent to receive electronic communications and You agree that all agreements, notices, disclosures and other communications that we provide to You electronically, via email and within the Service, satisfy any legal requirement that such communications be in writing.'
              />
              <Paragraph
                header='Monitoring and Enforcement'
                text='Arbithub reserves the right to investigate any violation of these Terms or misuse of the Service, including the removal of, disabling of access to, or modification of any content that violates these Terms.'
              />
              <Paragraph
                header='Termination/Access Restriction'
                text='Arbithub reserves the right, in its sole discretion, to terminate Your access to the Service and the related services or any portion thereof at any time, without notice.'
              />
              <Paragraph
                header='Indemnification'
                text='You agree to indemnify, defend and hold harmless Arbithub, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney’s fees) relating to or arising out of Your use of or inability to use the Service, Your violation of the Terms or Your violation of any rights of a third party, or Your violation of any applicable laws, rules or regulations. Arbithub reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by You, in which event You will fully cooperate with Arbithub in asserting any available defenses. '
              />
              <Paragraph
                header='Liability Disclaimer'
                text='THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SERVICE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. ARBITHUB LLC, AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME. 
ARBITHUB LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED IN THE SERVICE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND. ARBITHUB LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND ON-INFRINGEMENT.
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL ARBITHUB LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SERVICE, WITH THE DELAY OR INABILITY TO USE THE SERVICE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SERVICE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF AND ARBITHUB LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME COUNTRIES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE. 
'
              />
              <Paragraph
                header='Choice of Law and Dispute Resolution'
                text='Both parties to these Terms irrevocably agree the courts of the State of Illinois shall have exclusive jurisdiction to settle disputes arising out of this Agreement or relating to the use of the Service. Use of the Service is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section. 
Any dispute or claim arising out of use of Service or related to Terms of Service, including any question regarding its existence, validity or termination with Arbithub will be resolved individually without resort to class action. You agree to a waiver of Jury trial and Class Action Rights. 
You agree that no joint venture, partnership, employment, or agency relationship exists between You and Arbithub as a result of this agreement or use of the Service. Arbithub’s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of Arbithub’s right to comply with governmental, court and law enforcement requests or requirements relating to Your use of the Service or information provided or gathered by Arbithub with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect. 
'
              />
              <Paragraph
                header='Changes to Terms'
                text='Arbithub reserves the right, in its sole discretion, to change the Terms under which Arbithub is offered. The most current version of the Terms will supersede all previous versions. Arbithub encourages You to periodically review the Terms to stay informed of our updates. 
Contact details: help@arbithub.com
Arbithub LLC, 424 East State Parkway #131 Schaumburg, IL 60173
Updated: October 15, 2023
'
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TermsDetails
