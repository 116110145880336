import React, { useEffect, useState } from 'react'
import { GoReport } from 'react-icons/go'
import { FaPlus, FaMinus } from 'react-icons/fa6'
import { MdDeleteOutline } from 'react-icons/md'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { FaMagnifyingGlassPlus } from 'react-icons/fa6'
import { MdFavoriteBorder, MdOutlineFavorite } from 'react-icons/md'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector, useDispatch } from 'react-redux'
import { deleteFavorite } from '../../redux/Favorites/favorites.actions'
import { createFavorite } from '../../redux/Favorites/favorites.actions'
import { deleteProduct } from '../../redux/Products/products.actions'
import { searchButtons } from './data'
import ImageModal from './ImageModal'
import Tooltip from '../Shared/Tooltip'
import PuffLoader from 'react-spinners/PuffLoader'

const TableRows = ({ item, expandAll, columns, density, page, user }) => {
  const favorites = useSelector((state) => state.favoritesData.favorites)
  const loading = useSelector((state) => state.favoritesData.loading)
  const dispatch = useDispatch()
  const [isFav, setIsFav] = useState(false)
  const [currentItemAction, setCurrentItemAction] = useState(null)
  const [detailCols, setDetailCols] = useState(null)
  const [expand, setExpand] = useState(false)
  const [open, setOpen] = useState(false)
  const isUserAdmin = user?.userRoles.includes('admin')

  useEffect(() => {
    const isFavExist = favorites?.find((fav) => fav?.UPC === item?.UPC)
    setIsFav(isFavExist ? true : false)
  }, [favorites, item])

  useEffect(() => {
    !loading && setCurrentItemAction(null)
  }, [loading])

  const handleClickFav = () => {
    setCurrentItemAction(item?.UPC)
    if (!isFav) {
      const newFavorite = {
        userId: user?.id,
        UPC: item?.UPC,
      }
      dispatch(createFavorite(newFavorite))
    } else {
      dispatch(deleteFavorite({ UPC: item?.UPC, userId: user?.id }))
    }
  }

  const handleRemoveFavorite = () => {
    setCurrentItemAction(item?.UPC)
    dispatch(deleteFavorite({ UPC: item?.UPC, userId: user?.id }))
  }

  const handleRemoveProduct = (id) => {
    // Only admin user can delete a product
    if (id) dispatch(deleteProduct(id))
  }

  useEffect(() => {
    expandAll ? setExpand(true) : setExpand(false)
  }, [expandAll])

  useEffect(() => {
    const invisibleColumns = columns
      .slice(0, -1)
      .filter((col) => !col.isVisible)
    const arrLength = invisibleColumns?.length
    setDetailCols({
      left: invisibleColumns.slice(0, Math.ceil(arrLength / 2)),
      right: invisibleColumns.slice(Math.ceil(arrLength / 2), arrLength),
    })
  }, [columns])

  return (
    <>
      <tr className='group relative hover:bg-[#f5f5f5] hover:shadow-md border-t'>
        <td //expand icon column
          className='max-w-12 min-w-12 flex justify-center items-center'
          style={{ height: density }}
        >
          <div
            className='flex justify-center items-center w-9 h-9 rounded-full cursor-pointer transition-colors duration-200 hover:bg-[#e8e8e8]'
            onClick={() => setExpand(!expand)}
          >
            {expand ? <FaMinus /> : <FaPlus />}
          </div>
        </td>
        {columns?.map((col) =>
          !col.isVisible ? null : (
            <td // all data columns
              className='tbody-column-content'
              key={col.field}
              style={{
                minWidth: col.minWidth,
                maxWidth: col.maxWidth || undefined,
                textAlign: col.align,
                height: density,
              }}
            >
              {col.field === 'modProductThumbnail' ? (
                <img
                  src={item[col.field]}
                  alt='Product-image'
                  style={{
                    height: density,
                    objectFit: 'contain',
                    opacity: expand ? 0 : 1,
                    padding: '2px 0',
                  }}
                />
              ) : (
                <>
                  <span
                    className={`px-1.5 ${density === 80 ? 'line-clamp-3' : density === 60 ? 'line-clamp-2' : 'line-clamp-1'}`}
                  >
                    {item[col.field]}
                  </span>
                  <span className='col-tooltip invisible absolute w-max px-1 py-0.5 text-xs border border-gray-300 rounded-sm shadow-md bg-white z-10 transition-visible'>
                    {item[col.field]}
                  </span>
                </>
              )}
            </td>
          )
        )}
        <td // fav or delete icon according to the page
          className={`actions-col flex items-center ${isUserAdmin ? 'min-w-28 max-w-28' : 'min-w-20 max-w-20'}`}
        >
          <div
            className={`${page !== 'products' && 'hidden'} table-fav flex justify-center items-center text-gray-600 text-xl w-9 h-9 rounded-full cursor-pointer hover:bg-[#e8e8e8] transition-colors duration-200`}
            onClick={handleClickFav}
          >
            {currentItemAction === item.UPC && loading ? (
              <PuffLoader size={20} color='#c11600' />
            ) : isFav ? (
              <MdOutlineFavorite className='text-red-500' />
            ) : (
              <MdFavoriteBorder />
            )}
            <span className='tooltip-table-fav absolute right-3 top-12 invisible w-max px-1 py-0.5 text-xs border border-gray-300 rounded-sm shadow-md text-gray-800 bg-white z-10 transition-visible'>
              {isFav ? 'Remove Favorite' : 'Add To Favorites'}
            </span>
          </div>

          <div
            className={`${page === 'products' && 'hidden'} table-remove-fav invisible group-hover:visible flex justify-center items-center text-gray-600 text-xl w-9 h-9 rounded-full cursor-pointer hover:bg-[#e8e8e8] transition-colors duration-200`}
            onClick={handleRemoveFavorite}
          >
            {currentItemAction === item.UPC && loading ? (
              <PuffLoader size={20} color='#c11600' />
            ) : (
              <MdDeleteOutline />
            )}
            <span className='tooltip-remove-fav absolute right-0.5 top-12 invisible w-max px-1 py-0.5 text-xs border border-gray-300 rounded-sm shadow-md text-gray-800 bg-white z-10 transition-visible'>
              Remove Favorite
            </span>
          </div>
          <div
            className={`table-report invisible group-hover:visible flex justify-center items-center text-amber-600 text-xl w-9 h-9 rounded-full cursor-pointer hover:bg-[#e8e8e8] transition-colors duration-200`}
          >
            <GoReport />
            <span className='tooltip-report absolute right-0.5 top-12 invisible w-max px-1 py-0.5 text-xs border border-gray-300 rounded-sm shadow-md text-gray-800 bg-white z-10 transition-visible'>
              Report Product
            </span>
          </div>
          <div
            onClick={() => handleRemoveProduct(item.id)}
            className={`${(page !== 'products' || !isUserAdmin) && 'hidden'} table-del-product invisible group-hover:visible flex justify-center items-center text-gray-600 text-xl w-9 h-9 rounded-full cursor-pointer hover:bg-[#e8e8e8] transition-colors duration-200`}
          >
            <RiDeleteBin5Line />
            <span className='tooltip-del-product absolute right-0.5 top-12 invisible w-max px-1 py-0.5 text-xs border border-gray-300 rounded-sm shadow-md text-gray-800 bg-white z-10 transition-visible'>
              Delete Product
            </span>
          </div>
        </td>
      </tr>
      {expand && (
        <tr className={`expanded-row  ${expand && 'border-t-white'}`}>
          <td
            colSpan='99'
            className={`py-1 ${expand && 'border-b border-gray-200'}`}
          >
            <div className='min-h-40 min-w-[1004px] my-2 max-w-[1400px] flex gap-1'>
              <div className='w-2/12'>
                <div
                  className='group relative flex justify-center items-center min-h-40'
                  style={{ height: detailCols?.left?.length * 40 }}
                >
                  <img
                    src={item?.modProductThumbnail}
                    alt='Product-image'
                    className='h-full object-contain'
                  />
                  <FaMagnifyingGlassPlus
                    onClick={() => setOpen(true)}
                    className='group-hover:block absolute cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-70 text-xl hidden'
                  />
                </div>
              </div>
              <div className='w-8/12 flex justify-center gap-5'>
                <div className='h-min shadow-md rounded overflow-hidden'>
                  <ul>
                    {detailCols?.left?.map((leftCol, index) => (
                      <Tooltip key={index} tooltipText={item[leftCol?.tooltip]}>
                        <li
                          className={`flex flex-row items-center h-10 px-2 border-b border-gray-200 ${index % 2 === 0 ? 'bg-blue-200' : ''} cursor-pointer hover:bg-gray-200 transition-colors duration-100`}
                        >
                          <p className='min-w-40 text-base'>{leftCol.header}</p>
                          <p className='w-2 text-base'>:</p>
                          <p className='w-48 text-base line-clamp-1'>
                            {item[leftCol.field]}
                          </p>
                        </li>
                      </Tooltip>
                    ))}
                  </ul>
                </div>
                <div className='h-min shadow-md rounded overflow-hidden'>
                  <ul>
                    {detailCols?.right?.map((rightCol, index) => (
                      <Tooltip
                        key={index}
                        tooltipText={item[rightCol?.tooltip]}
                      >
                        <li
                          className={`flex flex-row items-center h-10 px-2 border-b border-gray-200 ${index % 2 === 0 ? 'bg-blue-200' : ''} cursor-pointer hover:bg-gray-200 transition-colors duration-100`}
                        >
                          <p className='min-w-40 text-base'>
                            {rightCol.header}
                          </p>
                          <p className='w-2 text-base'>: </p>
                          <p className='w-48 text-base line-clamp-1'>
                            {item[rightCol.field]}
                          </p>
                        </li>
                      </Tooltip>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='w-2/12'>
                <div className='min-w-24 flex flex-col justify-evenly items-center gap-4'>
                  <div className='flex gap-4'>
                    {searchButtons.map((button, index) => (
                      <Tooltip key={index} tooltipText={button.tooltipText}>
                        <a
                          href={item[button.URL]}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='flex justify-center items-center text-white bg-sky-600 rounded-lg text-md w-12 h-7 cursor-pointer shadow-md hover:bg-[#f39200] active:scale-95 '
                        >
                          <FontAwesomeIcon icon={button.icon} />
                        </a>
                      </Tooltip>
                    ))}
                  </div>
                  {['Marketplace To Buy', 'Marketplace To Sell'].map(
                    (buttonText) => (
                      <a
                        key={buttonText}
                        href={
                          buttonText === 'Marketplace To Buy'
                            ? item.buyLink
                            : item.sellLink
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                        className='flex justify-center items-center border border-sky-400 rounded-md px-3 h-9 cursor-pointer hover:shadow-sm hover:bg-gray-100 hover:border-sky-600 active:scale-95 transition-colors ease-in-out duration-300 '
                      >
                        {buttonText}
                      </a>
                    )
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
      {open && (
        <ImageModal {...{ open, setOpen, image: item?.modProductThumbnail }} />
      )}
    </>
  )
}

export default TableRows
