import React, { useState, useEffect } from 'react'
import Cover from './../../assets/cover.png'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { RecoverySchema } from './validationSchemas'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetPasswordStart,
  resetUserState,
  userError,
} from '../../redux/User/user.actions'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'

const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { resetPasswordSuccess, userErr } = useSelector((state) => state.user)
  const [isFieldFocused, setIsFieldFocused] = useState({
    email: false,
  })

  useEffect(() => {
    if (resetPasswordSuccess) {
      dispatch(resetUserState())
      navigate('/login')
    }
    return () => {
      dispatch(userError(null))
    }
  }, [resetPasswordSuccess, dispatch, navigate])

  useEffect(() => {
    if (userErr) {
      const timer = setTimeout(() => {
        dispatch(userError(null))
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [userErr])

  return (
    <div className='mx-auto border rounded-md max-w-[450px] text-gray-800 py-10 px-4 sm:px-8 mt-10 overflow-hidden'>
      <div
        className='flex items-center justify-center w-full h-20 hover:cursor-pointer'
        onClick={() => navigate('/')}
      >
        <img src={Cover} alt='Logo' className='h-16 w-[76px] mr-1' />
        <h1 className='text-[44px] text-gray-900'>arbithub</h1>
      </div>
      <div
        className={`flex items-center justify-center gap-1.5 h-10 w-full relative bg-red-500 text-sm text-gray-50 my-1 rounded-sm ${userErr ? 'left-0' : '-left-[420px]'} transition-all duration-300`}
      >
        <span
          className={`flex justify-center items-center text-sm font-bold bg-gray-50 text-red-500 h-[18px] w-[18px] rounded-full ${!userErr && 'hidden'}`}
        >
          !
        </span>
        <span>{userErr}</span>
      </div>
      <div className='text-center text-gray-700'>
        <p className='mb-3 font-semibold'>Forgot Your Password?</p>
        <p>
          Enter your email address and we&apos;ll send you a link to reset your
          password.
        </p>
      </div>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={RecoverySchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(resetPasswordStart(values))
          setSubmitting(false)
        }}
      >
        {({ values, handleBlur, isSubmitting }) => (
          <Form>
            <div className='relative h-[72px] pt-3'>
              <Field
                id='email'
                name='email'
                type='email'
                className='border rounded hover:border-black w-full h-11 px-2 focus:outline-red-900'
                onFocus={() =>
                  setIsFieldFocused({ ...isFieldFocused, email: true })
                }
                onBlur={(e) => {
                  setIsFieldFocused({ ...isFieldFocused, email: false })
                  handleBlur(e)
                }}
              />
              <label
                htmlFor='email'
                className={`absolute left-2 text-gray-700 cursor-text transition-all duration-300 ${isFieldFocused.email || values.email !== '' ? 'top-0.5 bg-white rounded px-1 text-xs' : 'top-1/2 transform -translate-y-1/2 text-md'} `}
              >
                Email Address
              </label>
              <ErrorMessage
                name='email'
                component='div'
                className='text-xs text-red-500 pl-2'
              />
            </div>

            <button
              type='submit'
              disabled={isSubmitting}
              className={`text-sm font-semibold hover:bg-opacity-95 w-full h-9 rounded mt-5 transition-all ${isSubmitting ? 'bg-gray-200 text-gray-600 shadow-sm' : 'bg-blue-500 text-white shadow-md'}`}
            >
              Send Reset Link
            </button>
          </Form>
        )}
      </Formik>
      <div className='relative my-10 border-t'>
        <span className='absolute bg-white text-gray-500 px-5 -top-3.5 left-1/2 transform -translate-x-1/2'>
          or
        </span>
      </div>
      <div className='text-center mt-4 text-sm text-gray-800'>
        Don&apos;t have an account?
        <Link to='/pricing'>
          <span className='text-blue-500 hover:text-blue-600 ml-1 underline'>
            Sign Up
          </span>
        </Link>
      </div>
      <Link to='/login'>
        <button
          type='button'
          className='flex items-center justify-center mx-auto gap-2 text-sm font-semibold border border-blue-100 hover:border-blue-300 hover:bg-gray-50 w-60 h-9 rounded mt-5 text-gray-700 transition-all duration-300'
        >
          <MdOutlineKeyboardBackspace className='text-lg' />
          Back to Sign In
        </button>
      </Link>
    </div>
  )
}

export default ResetPassword
