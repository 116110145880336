import './tooltipStyle.css'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TooltipConfiger = ({ tooltipText, children, placement = 'top' }) => (
  <OverlayTrigger
    placement={placement}
    delay={{ show: 300, hide: 0 }}
    overlay={
      <Tooltip style={{ display: !tooltipText && 'none' }}>
        {tooltipText}
      </Tooltip>
    }
  >
    {children}
  </OverlayTrigger>
)

export default TooltipConfiger
