import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
})

const useAuth = ({admin_only=false}) => {
  const { currentUser } = useSelector(mapState)
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser || (admin_only && !currentUser?.userRoles?.includes('admin'))) {
      navigate('/')
    }
  }, [currentUser, navigate])

  return currentUser
}

export default useAuth
