import React from 'react'
import { useSelector } from 'react-redux'

const Profile = () => {
  const user = useSelector((state) => state.user.currentUser)
  const formatDate = (date) => {
    if (date) {
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    } else {
      return 'Not Subscribed'
    }
  }

  const formatSubscriptionStatus = (date) => {
    if (!date) {
      return 'Subscription Not Active'
    }

    const endDate = new Date(date)
    const currentDate = new Date()

    if (endDate && endDate >= currentDate){
      return 'Subscription Active'
    }else{
      return 'Subscription Not Active'
    }
  }

  const cancelledUserInfo = [
    { title: 'Name', value: user?.displayName },
    { title: 'Email', value: user?.email },
    { title: 'Subscription Cancelled', value: '' },
    {
      title: 'Start Date',
      value: formatDate(user?.subscription?.planStartDate),
    },
    {
      title: 'End Date',
      value: formatDate(user?.subscription?.planEndDate),
    },
  ]

  const userInfo = [
    { title: 'Name', value: user?.displayName },
    { title: 'Email', value: user?.email },
    { title: formatSubscriptionStatus(user?.subscription?.planEndDate), value: '' },
    {
      title: 'Start Date',
      value: formatDate(user?.subscription?.planStartDate),
    },
    {
      title: 'Renewal Date',
      value: formatDate(user?.subscription?.planEndDate),
    },
  ]
  return (
    <div className='mx-auto border rounded-md max-w-[450px] text-gray-700 py-5 px-4 sm:px-8 my-7 overflow-hidden'>
      <div className='text-center text-xl font-semibold'>User Profile</div>

      <div className='flex text-center justify-center items-center mx-auto mt-7 mb-10 ring-2 ring-offset-1 ring-blue-300 rounded-full w-24 h-24 bg-orange-600'>
        {user?.photoURL ? (
          <img
            src={user?.photoURL}
            alt='Profile Photo'
            className='rounded-full w-full h-full object-cover'
          />
        ) : (
          <span className='text-[48px] font-semibold text-white'>
            {user?.displayName.charAt(0).toUpperCase()}
          </span>
        )}
      </div>

      {
        user?.subscription?.status === "cancelled" ?
          cancelledUserInfo.map((info, index) => (
            <div key={index} className='flex items-center mt-3 w-full'>
              <div
                className={`w-28 flex-shrink-0 font-semibold ${info.title === 'Subscription Cancelled' ? 'text-red-700' : 'text-green-700'}`}
              >
                {info.title}
              </div>
              <input
                value={info.value}
                readOnly
                className={`border rounded outline-0 px-2 flex-grow h-10 ${info.title === 'Subscription Cancelled' ? 'invisible' : 'visible'}`}
              />
            </div>
          ))
          :
          userInfo.map((info, index) => (
            <div key={index} className='flex items-center mt-3 w-full'>
              <div
                className={`w-28 flex-shrink-0 font-semibold ${info.title === 'Subscription Not Active' ? 'text-red-700' : 'text-green-700'}`}
              >
                {info.title}
              </div>
              <input
                value={info.value}
                readOnly
                className={`border rounded outline-0 px-2 flex-grow h-10 ${info.title === 'Subscription Active' || info.title === 'Subscription Not Active' ? 'invisible' : 'visible'}`}
              />
            </div>
          ))
      }
      
    </div>
  )
}

export default Profile
