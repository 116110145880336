import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { readFavorites } from '../../redux/Favorites/favorites.actions'
import { RxIdCard } from 'react-icons/rx'
import { ImTable } from 'react-icons/im'
import TableView from '../../components/Views/TableView'
import CardView from '../../components/Views/CardView'
import { modifyProductData } from '../../components/Shared/modifyProductData'

const Favorites = () => {
  const user = useSelector((state) => state.user.currentUser)
  const favorites = useSelector((state) => state.favoritesData.favorites)
  const dispatch = useDispatch()
  const [tableView, setTableView] = useState(false)
  const [modifiedFavs, setModifiedFavs] = useState([])

  useEffect(() => {
    const fetchAndModifyFavorites = () => {
      if (user?.id) {
        dispatch(readFavorites(user.id))
        const newFavorites = modifyProductData(favorites)
        setModifiedFavs(newFavorites)
      }
    }

    fetchAndModifyFavorites()
  }, [user, favorites, dispatch])

  return (
    <div className='font-sans text-gray-900 my-3 mx-2 min-h-[calc(100vh-405px)] md:min-h-[calc(100vh-335px)] lg:min-h-[calc(100vh-260px)]'>
      <div
        className={`flex justify-between items-center p-2 mb-3 rounded-md border shadow-md mx-auto ${tableView ? 'w-[100%] lg:w-[95%]' : 'max-w-[1100px]'}`}
      >
        <p className='text-lg font-bold'>MY FAVORITE PRODUCTS</p>
        <div
          className='flex justify-center items-center gap-2 w-32 h-9 bg-blue-950 text-white rounded-md shadow-md cursor-pointer hover:opacity-95 active:scale-95 '
          onClick={() => setTableView(!tableView)}
        >
          {tableView ? (
            <RxIdCard className='text-xl' />
          ) : (
            <ImTable className='text-xl' />
          )}
          <p className='min-w-[68px]'>
            {tableView ? 'Card View' : 'Table View'}
          </p>
        </div>
      </div>

      <div className={`${!tableView && 'flex justify-center'}`}>
        {favorites?.length < 1 ? (
          <div className='text-lg pt-5 w-full text-center'>
            You have no items in your favorites. Please add product to favorites
            from products page.
          </div>
        ) : tableView ? (
          <TableView data={modifiedFavs} page='favorites' />
        ) : (
          <CardView data={modifiedFavs} page='favorites' />
        )}
      </div>
    </div>
  )
}

export default Favorites
